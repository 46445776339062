import React from 'react'

import Column from '_shared/components/layout/Column'
import TextBox from '_shared/components/layout/TextBox'

import LockedBadge from '_shared/components/element/LockedBadge'

import ActionCTA from '_shared/components/action/ActionCTA'

const Controls = ({
  saveAction,
  cancelAction,
  removeAction,
  permissions,
  disabled,
  isNew,
}) => {
  const permissionDelete = permissions.includes('delete')
  const permissionSave = isNew ? permissions.includes('create') : permissions.includes('update')

  const readOnly = permissions.length === 1 && permissions[0] === 'read'

  const hasLock = saveAction && ((removeAction && !permissionDelete) || !permissionSave)

  return (
    <Column
      rawStyle={{
        position: 'absolute',
        bottom: '5rem',
        left: '-10rem',
        width: '10rem',
        padding: '1rem 1rem 1rem 1.5rem',
        backgroundColor: 'RGB(var(--theme_background_module))',
      }}
    >
      {hasLock && (
        <Locked readOnly={readOnly} />
      )}
      {saveAction && permissionSave && disabled && (
        <TextBox
          rawStyle={{
            fontSize: '0.75rem',
            marginBottom: '0.5rem',
            textAlign: 'center',
          }}
          gridAlign={'stretch'}
          noMargin
        >{'* Content required'}</TextBox>
      )}
      {saveAction && (
        <ActionCTA
          label={'Save'}
          type={disabled ? 'disabled' : 'important'}
          change={!disabled ? saveAction : null}
          locked={!permissionSave}
          gridAlign={'stretch'}
        />
      )}
      <ActionCTA
        label={'Back'}
        type={'unimportant'}
        change={cancelAction}
        gridAlign={'stretch'}
      />
      {removeAction && (
        <ActionCTA
          label={'Delete'}
          type={'caution'}
          change={removeAction}
          locked={!permissionDelete}
          gridAlign={'stretch'}
        />
      )}
    </Column>
  )
}

const Locked = (props) => {
  const {
    readOnly,
  } = props

  const message = readOnly ? 'Your user profile has viewing privileges only' : 'Your user profile has limited privileges'

  return (
    <Column type={'centered'} spread={false}>
      <LockedBadge gridAlign={'center'} />
      <TextBox textAlign={'center'}>{message}</TextBox>
    </Column>
  )
}

export default Controls
