import React, { Fragment } from 'react'

import Row from '_shared/components/layout/Row'
import InlineTextBox from '_shared/components/layout/InlineTextBox'

import ActionCTA from '_shared/components/action/ActionCTA'
import { Raw as InputIcon } from '_shared/components/input/InputIcon'
// import IconButton from 'molecules/IconButton'

import SearchIcon from 'icons/Search'
import SearchRefreshIcon from 'icons/SearchRefresh'

import Config from 'libs/Config'

const Nav = ({
  label,
  create,
  value,
  update,
  sorted,
  columns,
  canCreate,
}) => {
  const canSort = columns.find(({ key }) => key === sorted)

  return (
    <Row type={'spaced'} margin={'2rem 0 0 0'}>
      <ActionCTA
        type={canCreate ? 'important' : 'disabled'}
        label={label}
        change={canCreate ? create : null}
        locked={!canCreate}
      />
      {canSort && (
        <Fragment>
          <Row noFlex pushLeft gridAlign={'center'}>
            <SearchRefreshIcon
              fill={Config.theme.text_success}
              size={16}
            />
            <InlineTextBox color={'text_success'}>{`Search by ${sorted !== null ? canSort.label : ''}`}</InlineTextBox>
          </Row>
          <InputIcon
            placeholder={'Search'}
            value={value}
            change={(field, value) => update(value)}
            noFlex
            icon={(
              <SearchIcon
                size={20}
                fill={Config.theme.text}
              />
            )}
          />
        </Fragment>
      )}
    </Row>
  )
}

export default Nav
