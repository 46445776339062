import React, { Fragment, useState } from 'react'

import {
  useQuery,
} from 'react-query'

import sortBy from 'lodash/sortBy'

import eachDay from 'date-fns/each_day'

import Row from '_shared/components/layout/Row'
import Container from '_shared/components/layout/Container'
import InlineTextBox from '_shared/components/layout/InlineTextBox'
import TextBox from '_shared/components/layout/TextBox'
import ActionEdit from '_shared/components/action/ActionEdit'

import FlexButton from '_shared/components/element/FlexButton'

import PrevIcon from '_shared/icons/Prev'
import NextIcon from '_shared/icons/Next'

import VenueIcon from '_shared/icons/Venue'
import DurationIcon from '_shared/icons/Duration'

import format from 'date-fns/format'
import parse from 'date-fns/parse'

import LoadIndicator from '_shared/components/notification/LoadIndicator'

import {
  useScheduleFunc,
  useAPIScheduleFunc,
} from '_shared/hooks/useTemplate'

import Config from 'libs/Config'

import product from 'api/product'

import {
  stampToDateISO,
} from '_shared/libs/date'

const decorateRecord = (hours, minutes, record) => {
  return {
    time: `${hours}:${minutes}`,
    ...record,
  }
}

const decorate = (records) => {
  const output = []

  records.forEach(record => {
    const dateStamp = record.segments[0].start

    const [
      year,
      month,
      day,
    ] = dateStamp.split('T')[0].split('-')

    const [
      hours,
      minutes,
    ] = dateStamp.split('T')[1].split(':')

    const monthExists = output.find(item => item.month === `${year}-${month}`)

    if (monthExists) {
      const dayExists = monthExists.days.find(item => item.day === `${year}-${month}-${day}`)

      if (dayExists) {
        dayExists.times.push(decorateRecord(hours, minutes, record))
      } else {
        const newDay = {
          day: `${year}-${month}-${day}`,
          date: dateStamp,
          type: record.type,
          times: [
            decorateRecord(hours, minutes, record),
          ],
        }

        monthExists.days.push(newDay)
      }
    } else {
      const newMonth = {
        month: `${year}-${month}`,
        date: dateStamp,
        days: [
          {
            day: `${year}-${month}-${day}`,
            date: dateStamp,
            type: record.type,
            times: [
              decorateRecord(hours, minutes, record),
            ],
          },
        ],
      }

      output.push(newMonth)
    }
  })

  const sorted = sortBy(output, ['month'])

  // Sorry future me
  return sorted.map(month => {
    return {
      ...month,
      days: sortBy(
        month.days
          .map(day => {
            return {
              ...day,
              times: sortBy(day.times, ['time']),
            }
          }),
        ['day'],
      ),
    }
  })
}

// Pass up publish status to form from list when editing instance
const ScheduleList = ({
  type,
  data,
  edit,
  operator_type,
  startDate,
  endDate,
}) => {
  const getDecorated = useScheduleFunc()
  const getAPIDecorated = useAPIScheduleFunc()
  const display = decorate(data)

  const timeRowStyle = {
    display: 'grid',
    alignItems: 'center',
    gap: '1.5rem 0',
    gridTemplateColumns: '5rem 8rem 1fr 12rem',
    padding: '0.5rem',
    borderBottom: `1px solid ${Config.theme.border_thin}`,
  }

  return (
    <Container>
      {display.length === 0 && (
        <Container margin={'4rem 0'}>
          <TextBox size={1.25} strong textAlign={'center'} noMargin>{`There are no departures between ${startDate} and ${endDate}.`}</TextBox>
          <TextBox size={1.25} strong textAlign={'center'} margin={'1rem 0 0 0'}>{'Please select a different set of dates from the calendar pickers above.'}</TextBox>
        </Container>
      )}
      {display.map(section => {
        return section.days.map(day => {
          return (
            <Fragment key={day.date}>
              <DayRow date={day.date} />
              {day.times.map(({ time, ...rest }) => {
                const output = operator_type === 'scandlines' ? getAPIDecorated(rest) : getDecorated(rest)
                if (output === null) return null

                return (
                  <Container key={time} rawStyle={timeRowStyle} background={'background_module'}>
                    <InlineTextBox strong size={1.25}>{time}</InlineTextBox>
                    <ActionEdit label={'options'} change={() => edit(rest.entity_id)} />
                    <Row>
                      <VenueIcon fill={Config.theme.text} size={24} />
                      <Container>
                        <InlineTextBox strong block>{output.venue_title}</InlineTextBox>
                        <Row>
                          {output.layout_information.map(({ label, value, units }) => {
                            return (
                              <Container key={label}>
                                <Row>
                                  <InlineTextBox>{`${value} ${label}`}</InlineTextBox>
                                </Row>
                                <Row>
                                  <InlineTextBox color={'text_success'}>{`${units.reserved} Sold`}</InlineTextBox>
                                </Row>
                                <Row>
                                  <InlineTextBox color={'text_error'}>{`${units.available} Avail`}</InlineTextBox>
                                </Row>
                              </Container>
                            )
                          })}
                        </Row>
                      </Container>
                    </Row>
                    <Row type={'end'}>
                      <DurationIcon fill={Config.theme.text} size={24} />
                      <Container>
                        <Row>
                          {type === 'route' && output.segments.map((segment, index) => {
                            return (
                              <Row key={index} spread={false}>
                                <InlineTextBox strong>{segment.time}</InlineTextBox>
                                <InlineTextBox margin={'0 0 0 0.25rem'}>{segment.title}</InlineTextBox>
                              </Row>
                            )
                          })}
                          {type === 'event' && (
                            <InlineTextBox>{output.duration}</InlineTextBox>
                          )}
                        </Row>
                      </Container>
                      <InstanceStatus status={rest.published ? rest.status : 'draft'} />
                    </Row>
                  </Container>
                )
              })}
            </Fragment>
          )
        })
      })}
    </Container>
  )
}

// "active" | "suspended" | "cancelled" | "locked"

const mapStatus = {
  active: 'Live',
  draft: 'Draft',
  suspended: 'Frozen',
  cancelled: 'Cancelled',
}

const InstanceStatus = ({
  status,
}) => {
  let background = 'button_success'

  if (status === 'suspended') background = 'button_frozen'

  if (status === 'cancelled') background = 'button_stop'

  if (status === 'draft') background = 'button_warning'

  return (
    <Container background={background} padding={'0.25rem 1rem'}>
      <InlineTextBox color={'text_light'}>{mapStatus[status]}</InlineTextBox>
    </Container>
  )
}

const DayRow = ({ date }) => {
  const [
    day,
    year,
  ] = format(parse(date), 'DD dddd|MMM YYYY').split('|')

  return (
    <Row type={'spaced'} background={'text_mid'} padding={'0.5rem'}>
      <InlineTextBox strong color={'text_light'} size={1.25}>{day}</InlineTextBox>
      <InlineTextBox color={'text_light'} size={1.25}>{year}</InlineTextBox>
    </Row>
  )
}

const fetchInstances = (service, page) => {
  return product.listServiceInstances(service, page)
    .then(response => {
      return {
        ...response,
        entities: response.entities
          .map(({ entity_id, last_revision, published_revision }) => {
            return {
              entity_id,
              ...last_revision,
              published: Boolean(published_revision),
            }
          }),
      }
    })
}

const Paginator = ({
  service,
  startDate,
  endDate,
  ...rest
}) => {
  const [
    page,
    setPage,
  ] = useState(1)

  const options = {
    page,
    dates: eachDay(stampToDateISO(startDate), stampToDateISO(endDate))
      .map(stampToDateISO),
  }

  const instances = useQuery([`instance_list_${service}`, options], () => fetchInstances(service, options))

  let pages = null
  let entities = []

  if (instances.data) {
    pages = instances.data.pages
    entities = instances.data.entities
  }

  const handleNext = () => {
    const change = page + 1 > pages ? pages : page + 1

    setPage(change)
  }

  const handlePrev = () => {
    const change = page - 1 < 1 ? 1 : page - 1

    setPage(change)
  }

  return (
    <Fragment>
      {instances.isLoading && (
        <LoadIndicator />
      )}
      {!instances.isLoading && (
        <ScheduleList
          {...rest}
          startDate={startDate}
          endDate={endDate}
          data={entities}
        />
      )}
      {(pages !== null && pages > 1) && (
        <Row
          type={'spaced'}
          rawStyle={{
            padding: '1rem',
            height: '5rem',
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
            position: 'fixed',
            bottom: '0',
            right: '15rem',
            left: '15rem',
          }}
        >
          <FlexButton change={handlePrev} background={'button'} width={'3rem'} noFlex>
            <PrevIcon
              size={24}
              fill={Config.theme.text_light}
            />
          </FlexButton>
          <InlineTextBox>{`${page} of ${pages}`}</InlineTextBox>
          <FlexButton change={handleNext} background={'button'} width={'3rem'} noFlex>
            <NextIcon
              size={24}
              fill={Config.theme.text_light}
            />
          </FlexButton>
        </Row>
      )}
    </Fragment>
  )
}

export default Paginator
