import React from 'react'
import styles from './ctabutton.scss'

import StyleWrapper from '_shared/style/StyleWrapper'

const CTAButton = ({
  change,
  children,
  disabled = false,
  ...style
}) => {

  return (
    <StyleWrapper
      {...style}
      render={styling => {
        return (
          <button
            style={styling}
            className={styles.button}
            onClick={!disabled ? change : undefined}
            disabled={disabled}
          >
            {children}
          </button>
        )
      }}
    />
  )
}

export default CTAButton
