import React from 'react'

import Heading from '_shared/components/layout/Heading'
import Area from '_shared/components/layout/Area'

import InputCheckBox from '_shared/components/input/InputCheckBox'
import InputRadio from '_shared/components/input/InputRadio'

import { Raw as InputCurrency } from '_shared/components/input/InputCurrency'

import Table from '_shared/components/element/Table'
import ListTable from '_shared/components/navigation/ListTable'

const styles = {
  head: {
    fontWeight: 'bold',
    borderBottomWidth: 0,
  },
  body: {
    backgroundColor: 'RGB(var(--theme_background_module))',
    padding: '2rem',
    height: '3.5rem',
    fontSize: '1rem',
    borderRadius: '1rem',
  },
  field: {
    background: 'RGB(var(--theme_button_inactive))',
    padding: '0.5rem 0rem',
  },
}

const generateRows = ({
  markets = [],
  value = [],
}) => {
  return markets.map((market) => {
    const exists = value.find((effect) => effect.market_id == market.entity_id)

    return {
      title: market.title,
      market_id: market.entity_id,
      value: exists ? exists.value : null,
      currency: markets.find((el) => el.entity_id == market.entity_id)?.currencies[0].id,
    }
  })
}

const FeeTable = ({
  title,
  field,
  value = [],
  refundField,
  refundValue,
  options = [{ value: 'inactive', label: 'No Effect' }],
  markets = [],
  enabled,
  selected,
  showRefund = false,
  change,
  legacy = true,
}) => {
  const handleUpdateFeeState = (fieldName, value) => {
    if (value !== 'inactive') {
      change(`${field}_type`, value)
      change(`${field}_enabled`, true)
    } else {
      change(`${field}_enabled`, false)
    }
  }

  const handleChangeFee = (market, amount) => {
    const index = value.findIndex((effect) => effect.market_id == market)

    if (index > -1) {
      change(`${field}_values[${index}].value`, amount)
    } else {
      change(`${field}_values`, [
        ...value,
        {
          market_id: market,
          value: amount,
          type: 'set_fixed',
        },
      ])
    }
  }

  const rows = generateRows({ markets, value })

  const mutatedData = rows.map(
    (row) => ({
      amount: (
        <InputCurrency
          field={row.market_id}
          value={row.value}
          currency={row.currency}
          change={handleChangeFee}
          status={row.value !== null ? 'VALID' : null}
          width={'10rem'}
          minHeight={'1.5rem'}
          rawStyle={legacy ? {} : styles.field}
        />
      ),
      market: row.title,
    }),
  )

  const active_option = enabled ? selected : 'inactive'

  return (
    <Area
      area={'fees'}
      rowgap={1.5}
      columns={1}
    >
      <Heading level={2} title={title} />
      <InputRadio
        type={'horizontal'}
        field={'type'}
        options={options}
        value={active_option}
        change={handleUpdateFeeState}
        margin={'1rem 0 0 0'}
      />
      {showRefund && active_option !== 'inactive' && (
        <InputCheckBox
          type={'horizontal'}
          field={refundField}
          title={'Fee refundable'}
          value={refundValue}
          change={change}
        />
      )}
      {enabled && (
        <LayoutComponent
          legacy={legacy}
          data={mutatedData}
        />
      )}
    </Area>
  )
}

const columns = [
  {
    key: 'market',
    label: 'Market',
  },
  {
    key: 'amount',
    label: 'Amount',
  },
]

const LayoutComponent = ({
  data,
  legacy,
}) => {
  if (legacy) {
    return (
      <ListTable
        columns={columns}
        data={data}
      />
    )
  } else {
    return (
      <Table
        columns={columns}
        data={data}
        headRowStyle={styles.head}
        bodyRowStyle={styles.body}
      />
    )
  }
}

export default FeeTable