const readShortcuts = () => {
  const {
    app,
  } = window.TICKNOVATE_CONFIG

  if (!app.shortcuts) return false

  const populated = Object.keys(app.shortcuts)
    .filter(key => app.shortcuts[key].length > 0)

  return populated.length > 0
}

export {
  readShortcuts,
}
