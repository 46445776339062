import React from 'react'

import InputFilter from '_shared/components/input/InputFilter'

const options = [
  {
    label: 'Order',
    value: 'order',
  },
  {
    label: 'Payment',
    value: 'payment',
  },
  {
    label: 'Refund',
    value: 'refund',
  },
]

const ChooserExternalOrderType = ({
  data,
  change,
}) => {
  const handleChange = (field, value) => {
    change([{
      field,
      value,
    }])
  }

  return (
    <InputFilter
      field={'external-order-type'}
      title={'Filter External Order Type'}
      placeholder={'Filter External Order Type'}
      value={data['external-order-type']}
      change={handleChange}
      options={options}
    />
  )
}

export default ChooserExternalOrderType
