import React from 'react'

import NavIcon from './NavIcon'

import View from '_shared/components/layout/View'
import Heading from '_shared/components/layout/Heading'

import SideNavigation from '_shared/components/navigation/SideNavigation'

import TicketTypes from './subviews/TicketTypes'
import Locations from './subviews/Locations'
import CapacityTypes from './subviews/CapacityTypes'
import Vehicles from './subviews/Vehicles'
import Venues from './subviews/Venues'
import Terms from './subviews/Terms'

const BuildingBlocks = (props) => {
  const options = [
    {
      title: 'Ticket Types',
      view: TicketTypes,
      slug: 'tickets',
    },
    {
      title: 'Capacity Types',
      view: CapacityTypes,
      slug: 'capacity',
    },
    {
      title: 'Vehicles',
      view: Vehicles,
      slug: 'vehicles',
    },
    {
      title: 'Locations & Stations',
      view: Locations,
      slug: 'locations',
    },
    {
      title: 'Venues',
      view: Venues,
      slug: 'venues',
    },
    {
      title: 'Cancellation & Amendments',
      view: Terms,
      slug: 'cancellations',
    },
  ]

  return (
    <View>
      <Heading
        level={'section'}
        title={'Building Blocks'}
      />
      <SideNavigation
        options={options}
      />
    </View>
  )
}

export default BuildingBlocks

export {
  NavIcon,
}