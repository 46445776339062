import React from 'react'
import {
  connect,
} from 'react-redux'

import styles from './navigator.scss'

import {
  NavLink,
} from 'react-router-dom'

import {
  logout,
} from 'actions/user'

import TextBox from '_shared/components/layout/TextBox'

import routes from 'config/routes'

import Logo from 'icons/Logo'

import LinkContainer from './LinkContainer'
import LinkContent from './LinkContent'

import UserIcon from 'icons/User'
import LogOutIcon from 'icons/LogOut'

import upperCaseFirst from 'libs/upperCaseFirst'

import Config from 'libs/Config'

import styleCombine from '_shared/libs/styleCombine'

const hasPermission = (permissions) => (capability) => capability ? !capability.every(item => permissions.hasOwnProperty(item) && permissions[item].includes('read')) : false

const Navigator = ({
  name,
  role,
  logout,
  checkLocked,
  collapsed,
}) => {
  return (
    <div className={styleCombine(styles.layout, collapsed && styles.collapsed)}>
      <div className={styles.logo}>
        <NavLink to={'/'}>
          <Logo />
        </NavLink>
      </div>
      <ul className={styles.navgroup}>
        {routes
          .filter(item => item.inNav)
          .map(item => {
            return (
              <NavItem
                key={item.path}
                item={item}
                checkLocked={checkLocked}
                collapsed={collapsed}
              />
            )
          })}
        <LogOut
          name={name}
          role={role}
          logout={logout}
          collapsed={collapsed}
        />
      </ul>
    </div>
  )
}

const NavItem = (props) => {
  const {
    item,
    checkLocked,
    collapsed,
  } = props

  const locked = checkLocked(item.capability)

  return (
    <li className={styles.wrapper}>
      <LinkContainer path={item.navTo} notLink={locked} collapsed={collapsed}>
        <LinkContent item={item} locked={locked} collapsed={collapsed} />
      </LinkContainer>
    </li>
  )
}

const LogOut = (props) => {
  const {
    name,
    role,
    logout,
    collapsed,
  } = props

  return (
    <li className={styles.wrapper}>
      <div className={styleCombine(styles.link, collapsed && styles.collapsed)}>
        {!collapsed && (
          <UserIcon
            fill={Config.theme.nav_icon}
            size={20}
          />
        )}
        <div className={styles.logout}>
          <TextBox color={'nav_text'} noMargin texAlign={'center'}>{name}</TextBox>
          <TextBox color={'nav_icon'} margin={'0.25rem 0 0 0'} texAlign={'center'}>{upperCaseFirst(role)}</TextBox>
        </div>
      </div>
      <ul className={styles.subNav}>
        <li className={styles.wrapper}>
          <div className={styles.link} onClick={logout}>
            <LinkContent item={{
              icon: LogOutIcon,
              display: 'Logout',
            }} />
          </div>
        </li>
      </ul>
    </li>
  )
}

const mapStateToProps = (state) => {
  const {
    user,
  } = state.user

  const {
    ui,
  } = state

  let role = user.roles
    .split(',')
    .filter(role => role.split(':')[0] === 'admin')
    .map(role => role.split(':')[1])[0]

  return {
    collapsed: ui.collapsed,
    name: user.name.split(' ')[0],
    role,
    permissions: user.permissions,
    checkLocked: hasPermission(user.permissions),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logout()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Navigator)
