import React from 'react'

import FormView from '_shared/components/layout/FormView'
import Area from '_shared/components/layout/Area'
import Row from '_shared/components/layout/Row'

import SectionWrapper from '_shared/components/layout/SectionWrapper'
import Heading from '_shared/components/layout/Heading'
import TextBox from '_shared/components/layout/TextBox'

import InputRadio from '_shared/components/input/InputRadio'
import InputISOInterval from '_shared/components/input/InputISOInterval'

const FormRules = ({
  current,
  update,
  updateSingle,
}) => {
  const handleDatesValueSwitch = (field, value) => {
    update([
      {
        field,
        value,
      },
      {
        field: 'combo_limitations.max_total_interval',
        value: null,
      },
    ])
  }

  return (
    <FormView>
      <Area
        areas={[
          'section_return',
          'return',
          'section_same',
          'same',
        ]}
        rowgap={2}
      >
        <SectionWrapper area={'section_return'}>
          <Heading level={1} title={'Return journey'} />
        </SectionWrapper>
        <Area
          area={'return'}
          areas={[
            'info',
            'type/2 . .',
          ]}
          rowgap={1}
        >
          <TextBox strong area={'info'}>{'Define whether the combo is a return journey. A return journey will apply/enforce the same journey is in reverse.'}</TextBox>
          <InputRadio
            area={'type'}
            type={'horizontal'}
            field={'combo_limitations.return'}
            value={current.combo_limitations.return}
            options={[
              {
                value: true,
                label: 'Return journey',
              },
              {
                value: false,
                label: 'Do NOT enforce a return',
              },
            ]}
            change={updateSingle}
          />
        </Area>
        <SectionWrapper area={'section_same'}>
          <Heading level={1} title={'Combo item dates'} />
        </SectionWrapper>
        <Area
          area={'same'}
          areas={[
            'info',
            'type/2 . .',
            'info_2',
            'time/2 . .',
          ]}
          rowgap={1}
        >
          <TextBox strong area={'info'}>{'For the combo to apply, the items in the combo must be booked for the same day.'}</TextBox>
          <InputRadio
            area={'type'}
            type={'horizontal'}
            field={'combo_limitations.same_day'}
            value={current.combo_limitations.same_day}
            options={[
              {
                value: true,
                label: 'Must book items for the same day',
              },
              {
                value: false,
                label: 'Can book for different days',
              },
            ]}
            change={handleDatesValueSwitch}
          />
          {current.combo_limitations.same_day === false && (
            <Row area={'time'}>
              <InputISOInterval
                title={'Max time between combo items'}
                field={'combo_limitations.max_total_interval'}
                value={current.combo_limitations.max_total_interval}
                change={updateSingle}
              />
            </Row>
          )}
        </Area>
      </Area>
    </FormView>
  )
}

export default FormRules
