import React from 'react'

import styles from './historyfull.scss'

import Column from '_shared/components/layout/Column'
import TextBox from '_shared/components/layout/TextBox'

import LockedBadge from '_shared/components/element/LockedBadge'

import ActionCTA from '_shared/components/action/ActionCTA'

import styleCombine from '_shared/libs/styleCombine'

const required = {
  position: 'absolute',
  top: '-1.5rem',
  left: 0,
  right: 0,
  fontSize: '0.75rem',
  padding: '0.25rem',
  height: '1.5rem',
  textAlign: 'center',
  backgroundColor: 'RGB(var(--theme_background_module))',
}

const Controls = ({
  saveAction,
  cancelAction,
  permissions,
  disabled,
  isNew,
  isMounted,
}) => {
  const permissionSave = isNew ? permissions.includes('create') : permissions.includes('update')

  const readOnly = permissions.length === 1 && permissions[0] === 'read'

  const hasLock = saveAction && !permissionSave

  return (
    <div className={styleCombine(styles.controls, isMounted ? styles.in : styles.out)}>
      {hasLock && (
        <Locked readOnly={readOnly} />
      )}
      {saveAction && permissionSave && disabled && (
        <TextBox rawStyle={required} noMargin>{'* Content required'}</TextBox>
      )}
      <ActionCTA
        label={'Discard Changes'}
        type={'unimportant'}
        change={cancelAction}
      />
      {saveAction && (
        <ActionCTA
          label={'Save Changes'}
          type={disabled ? 'disabled' : 'important'}
          change={!disabled ? saveAction : null}
          locked={!permissionSave}
        />
      )}
    </div>
  )
}

const Locked = (props) => {
  const {
    readOnly,
  } = props

  const message = readOnly ? 'Your user profile has viewing privileges only' : 'Your user profile has limited privileges'

  return (
    <Column>
      <LockedBadge />
      <TextBox noMargin textAlign={'center'} >{message}</TextBox>
    </Column>
  )
}

export default Controls
