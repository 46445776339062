import React from 'react'

import sortedUniq from 'lodash/sortedUniq'
import * as duration from 'duration-fns'

import FormView from '_shared/components/layout/FormView'
import Area from '_shared/components/layout/Area'
import Row from '_shared/components/layout/Row'
import SectionWrapper from '_shared/components/layout/SectionWrapper'
import Heading from '_shared/components/layout/Heading'
import TextBox from '_shared/components/layout/TextBox'
import InputDate from '_shared/components/input/InputDate'
import InfoBox from '_shared/components/notification/InfoBox'
import InputRadio from '_shared/components/input/InputRadio'
import ListTable from '_shared/components/navigation/ListTable'
import { Raw as InputInteger } from '_shared/components/input/InputInteger'
import { Raw as InputISOInterval } from '_shared/components/input/InputISOInterval'

import useMergedTickets from '_shared/hooks/useMergedTickets'
import useDependencies from '_shared/hooks/useDependencies'

import channel_map from 'config/channels'
import Selector from 'templates/Selector'

import ChannelMatrix from './ChannelMatrixActual'
import filterTickets from './filterTickets'

const limitOptions = [
  { value: 'none', label: 'No capacity or ticket limits' },
  { value: 'capacity_unit', label: 'Limit overall passenger capacity per booking' },
  { value: 'ticket', label: 'Limit overall ticket availability per booking' },
]

const FormAvailability = ({
  current,
  update,
  updateSingle,
}) => {
  const { data: markets } = useDependencies('markets', data => {
    return data.map(market => {
      return market.entity_id
    })
  })

  const { data: services } = useDependencies(
    'services',
    data => data
      .filter(record => record.type === current.type),
  )

  const { data: ticket_types } = useMergedTickets()
  if (ticket_types.length === 0) return null

  const ticket_options = current.services
    .filter(item => item.entity_id !== null)
    .map(item => item.entity_id)
    .reduce((acc, entity_id) => {
      const outer = services.find(item => item.entity_id === entity_id)

      if (outer) {
        const tickets = outer.ticket_types
          .map(type => type.ticket_type.entity_id)
          .filter(type => !acc.includes(type))

        acc.push(...tickets)
      }
      return sortedUniq(acc)
    }, [])
    .map(type => {
      const { value, label } = ticket_types.find(item => item.value === type)
      return { value, label }
    })

  const handleTicketChange = (field, value) => {
    const types = value
      .map(type => type.ticket_type.entity_id)
      .filter(Boolean)

    const state = filterTickets(types, markets, current.pricing)

    updateSingle('allowed_ticket_types', value)
    updateSingle('pricing', state)
  }

  const handleDateTypeChange = (field, value) => {
    if (value === 'fill') {
      update([
        {
          field: 'sales_limitations.date.start_date',
          value: new Date().toISOString().split('T')[0],
        },
        {
          field: 'sales_limitations.date.end_date',
          value: new Date().toISOString().split('T')[0],
        },
      ])
    } else {
      update([
        {
          field: 'sales_limitations.date.start_date',
          value: null,
        },
        {
          field: 'sales_limitations.date.end_date',
          value: null,
        },
      ])
    }
  }

  const dates_empty = current.sales_limitations.date.start_date === null && current.sales_limitations.date.end_date === null

  /**
   * Basically in this function we are saying have they set the value to 0?
   * If so we're removing that channel from the array.
   * If the value isn't 0 and the channel exists in the array then,
   * we'll just change it's value or else we'll have to create a new object in the array with the value.
   * Then bundle this backup to the state.
   */
  const handleEndSalesLimitChange = (value, channel) => {
    let state = current.sales_limitations.sales_end_date
    const normalisedValue = duration.toString(duration.parse(value))
    const exists = state.findIndex((effect) => effect.channel === channel)

    if (value == 0) {
      state = state.filter((effect) => effect.channel != channel)
    } else if (exists > -1) {
      state[exists] = {
        ...state[exists],
        start_offset: normalisedValue,
      }
    } else {
      state.push({
        start_offset: normalisedValue,
        channel,
      })
    }

    updateSingle('sales_limitations.sales_end_date', state)
  }

  const salesEndData = Object.values(channel_map).map((channel, index) => {
    const exists = current.sales_limitations.sales_end_date.find(effect => effect.channel === index + 1)

    if (!exists) {
      return {
        channel: index + 1,
        start_offset: 0,
        time: 'days',
      }
    }

    const parsedDuration = duration.parse(exists.start_offset)
    const nonZeroValue = Object.entries(parsedDuration).filter(([, value]) => value !== 0)

    return {
      channel: index + 1,
      start_offset: exists.start_offset,
      time: nonZeroValue.length > 0 ? nonZeroValue[0][0] : 0,
    }
  })

  return (
    <FormView>
      <Area
        areas={[
          'section_purchase',
          'available',
          'sales_end',
          'section_tickets',
          'tickets',
          'section_channels',
          'channels',
        ]}
        rowgap={2}
      >
        <SectionWrapper area={'section_purchase'}>
          <Heading level={1} title={'Available to purchase'} />
        </SectionWrapper>
        <Area
          area={'available'}
          areas={[
            'info',
            'type',
            'dates/2 . .',
          ]}
          rowgap={1}
        >
          <TextBox strong area={'info'}>{'Limit when this product will be available for purchase.'}</TextBox>
          <InputRadio
            area={'type'}
            type={'horizontal'}
            field={'date_type'}
            value={dates_empty ? 'empty' : 'fill'}
            options={[
              {
                value: 'empty',
                label: 'Available as long as the product is live',
              },
              {
                value: 'fill',
                label: 'Limit availability to dates',
              },
            ]}
            change={handleDateTypeChange}
          />
          {!dates_empty && (
            <Availability
              area={'dates'}
              current={current}
              updateSingle={updateSingle}
            />
          )}
        </Area>
        <Area
          area={'sales_end'}
          areas={[
            'timeHeading',
            'timeInfo',
            'timeType',
            'selector/2 . .',
          ]}
          rowgap={1}
        >
          <Heading area={'timeHeading'} level={2} title={'End sales before scheduled start'} />
          <TextBox strong area={'timeInfo'}>{'Stop selling scheduled departure/event in a defined time before the scheduled start.'}</TextBox>
          <InputRadio
            area={'timeType'}
            type={'horizontal'}
            field={'sales_limitations.show_sales_end'}
            value={current.sales_limitations.show_sales_end}
            options={[
              {
                value: false,
                label: 'Available to purchase until scheduled start',
              },
              {
                value: true,
                label: 'Stop sales at specific time',
              },
            ]}
            change={updateSingle}
          />
          {
            current.sales_limitations.show_sales_end && (
              <LimitTimes
                area={'selector'}
                data={salesEndData}
                columns={[
                  {
                    key: 'channel',
                    label: 'Channel',
                  },
                  {
                    key: 'start_offset',
                    label: 'Stop Sales',
                  },
                ]}
                change={handleEndSalesLimitChange}
                placeholder={'Choose a ticket'}
              />
            )}
        </Area>
        <SectionWrapper area={'section_tickets'}>
          <Heading level={1} title={'Available for tickets'} />
        </SectionWrapper>
        <Area
          area={'tickets'}
          areas={[
            'info',
            'selector/2 . . warning/2',
            'limit_type',
            'limit_availability',
          ]}
          rowgap={1}
        >
          <TextBox strong area={'info'}>{'Limit what tickets are available for purchase.'}</TextBox>
          <Selector
            area={'selector'}
            outer_field={'tickets'}
            inner_field={'ticket_type'}
            data={current.allowed_ticket_types}
            options={ticket_options}
            columns={[
              {
                key: 'ticket_type',
                label: 'Ticket Type',
              },
            ]}
            change={handleTicketChange}
            placeholder={'Choose a ticket'}
            schema_generator={() => ({
              ticket_type: {
                entity_id: 'ticket_type.entity_id',
              },
            })}
            allowCreate={ticket_options.filter(({ disabled }) => !disabled).length > 0}
          />
          <InfoBox
            type={'warning'}
            title={'warning'}
            area={'warning'}
            content={'Making changes here will impact pricing and pricing rules and may result in loss of data with existing products.'}
          />
          <InputRadio
            area={'limit_type'}
            type={'horizontal'}
            title="Limit overall ticket availability"
            field={'qty_limit_per_booking_type'}
            value={current.qty_limit_per_booking_type}
            options={limitOptions}
            change={updateSingle}
            margin={'1rem 0 0 0'}
          />
          <Row area={'limit_availability'}>
            <InputInteger
              field={'qty_limit_per_booking_min'}
              value={current.qty_limit_per_booking_min == null ? 0 : current.qty_limit_per_booking_min}
              disabled={current.qty_limit_per_booking_type === 'none'}
              change={updateSingle}
              width={'5rem'}
              gridAlign={'center'}
              noFlex
            />
            <TextBox gridAlign={'center'}>{'Min'}</TextBox>
            <InputInteger
              field={'qty_limit_per_booking_max'}
              value={current.qty_limit_per_booking_max == null ? 0 : current.qty_limit_per_booking_max}
              disabled={current.qty_limit_per_booking_type === 'none'}
              change={updateSingle}
              width={'5rem'}
              gridAlign={'center'}
              noFlex
            />
            <TextBox gridAlign={'center'}>{'Max'}</TextBox>
          </Row>
        </Area>
        <SectionWrapper area={'section_channels'}>
          <Heading level={1} title={'Markets and channels'} />
        </SectionWrapper>
        <Area
          area={'channels'}
          areas={[
            'info',
            'selector',
          ]}
          rowgap={1}
        >
          <TextBox strong area={'info'}>{'Limit when this product to be purchased through certain channels.'}</TextBox>
          <ChannelMatrix
            area={'selector'}
            current={current}
            change={updateSingle}
          />
        </Area>
      </Area>
    </FormView>
  )
}

const fieldStyle = {
  flex: '0 0 auto',
  minHeight: '1.5rem',
  width: '10rem',
}

const LimitTimes = ({
  data,
  change,
  columns,
}) => {
  const mutatedData = data.map(({
    channel,
  }) => ({
    channel: channel_map[channel],
    start_offset: (
      <InputISOInterval
        field={'rule.date.after_offset_from_now'}
        value={data.find((effect) => effect.channel === channel)?.start_offset}
        {...fieldStyle}
        change={(field, value) => {
          change(value, channel)
        }}
        optionExtension={'before start time'}
        split
      />
    ),
  }))

  return (
    <ListTable
      columns={columns}
      data={mutatedData}
    />
  )
}

const Availability = ({
  area,
  current,
  updateSingle,
}) => {
  return (
    <Area
      area={area}
      areas={[
        'start . end .',
      ]}
      rowgap={1}
      colgap={1}
      columns={['10rem', '2rem', '10rem', '1fr']}
    >
      <InputDate
        area={'start'}
        title={'Start date'}
        field={'sales_limitations.date.start_date'}
        value={current.sales_limitations.date.start_date}
        placeholder={'Start'}
        change={updateSingle}
      />
      <InputDate
        area={'end'}
        title={'End date'}
        field={'sales_limitations.date.end_date'}
        value={current.sales_limitations.date.end_date}
        placeholder={'End'}
        change={updateSingle}
      />
    </Area>
  )
}

export default FormAvailability
