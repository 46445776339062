import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'

import Config from 'libs/Config'

import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

Sentry.init({
  dsn: process.env.NODE_ENV === 'production' && 'https://fdf02b8acdc94fe0b84f1d8bbd57e820@o1121825.ingest.sentry.io/6158856',
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
})

async function initApp () {
  // console.log(`Starting App with build: ${process.env.REACT_APP_BUILD_STAMP}`)
  Config.init()

  await Config.loadConfig()
  console.log('POST LOAD config', Config.config)

  ReactDOM.render((
    <App />
  ), document.getElementById('root'))
}

initApp()
