import React, { useState } from 'react'
import {
  connect,
} from 'react-redux'

import {
  forgotConfirm,
} from 'actions/user'

import {
  createNotification,
} from 'actions/notifications'

import {
  withValidator,
  validate,
  formValid,
} from './formValidator'

import Area from '_shared/components/layout/Area'

import { Raw as InputText } from '_shared/components/input/InputText'
import { Raw as InputPassword } from '_shared/components/input/InputPassword'
import Button from '_shared/components/element/Button'

import Heading from '_shared/components/layout/Heading'
import TextBox from '_shared/components/layout/TextBox'

import Row from '_shared/components/layout/Row'

import ActionCTA from '_shared/components/action/ActionCTA'

import PlusIcon from 'icons/Plus'

import Config from 'libs/Config'

import {
  login_action_map,
} from '../Routes'

import notEmptyOrNull from 'libs/notEmptyOrNull'

const passShape = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}/

const validPassword = (record, value) => passShape.test(value)

const matches = (record, value) => (notEmptyOrNull(record, record.password) && value === record.password)

const Validator = withValidator({
  username: notEmptyOrNull,
  password: validPassword,
  code: notEmptyOrNull,
  match: matches,
})

const ValidTextField = validate(InputText)
const ValidPassField = validate(InputPassword)

const ValidSubmit = formValid((props) => {
  const {
    action,
    status,
  } = props

  return (
    <ActionCTA
      type={status ? 'important' : 'disabled'}
      label={'Request Reset'}
      change={status ? action : null}
    />
  )
})

const ForgotConfirm = ({ history, location, forgotConfirm, notify }) => {
  const [
    state,
    setState,
  ] = useState({
    username: '',
    code: '',
    password: '',
    match: '',
    ...location.state,
  })

  const update = (field, value) => {
    const record = { ...state }

    record[field] = value

    setState(record)
  }

  const submit = async () => {
    try {
      const response = await forgotConfirm(state)

      const {
        message,
      } = response

      notify({
        type: 'save',
        message,
      })

      history.push('/login', { username: state.username })
    } catch (error) {
      console.error(error)

      const {
        type,
        message,
      } = error

      if (login_action_map[type]) {
        message && notify({
          type: 'error',
          message,
        })

        history.push(login_action_map[type], { username: state.username })

        return
      }

      if (type === 'GENERIC' || type === 'CODE_MISMATCH') {
        notify({
          type: 'error',
          message,
        })

        return
      }

      notify({
        type: 'error',
        message: 'An unknown error has occured',
      })
    }
  }

  const back = () => {
    history.push('/login')
  }

  return (
    <Validator
      record={state}
    >
      <form onSubmit={event => event.preventDefault()}>
        <Area
          areas={[
            'heading',
            'message/3 .',
            'form_a/3 .',
            'form_b/3 .',
            'back .',
          ]}
          columns={4}
          rowgap={1}
        >
          <Heading level={'section'} title={'Reset your password'} area={'heading'} />
          <TextBox noMargin color={'text_light'} area={'message'}>{'We have sent a code to your phone or email. Submit the code along with your email and new password.'}</TextBox>
          <Row type={'start'} area={'form_a'}>
            <ValidTextField
              field={'username'}
              placeholder={'Email'}
              value={state.username}
              change={update}
              requiredText={'User email required'}
              width={'15rem'}
              noFlex
            />
            <ValidTextField
              field={'code'}
              placeholder={'code'}
              value={state.code}
              change={update}
              requiredText={'Conformation code required'}
              width={'15rem'}
              noFlex
            />
          </Row>
          <Row type={'start'} area={'form_b'}>
            <ValidPassField
              field={'password'}
              placeholder={'New password'}
              value={state.password}
              change={update}
              requiredText={'Password must have one uppercase letter, one digit, one symbol and be 8 characters in length'}
              width={'15rem'}
              noFlex
            />
            <ValidPassField
              field={'match'}
              placeholder={'Type your password again'}
              value={state.match}
              change={update}
              requiredText={'Passwords must match'}
              width={'15rem'}
              noFlex
            />
            <ValidSubmit
              action={submit}
            />
          </Row>
          <Button change={back} margin={'1rem 0 0 0'} area={'back'}>
            <PlusIcon size={10} fill={Config.theme.text_light} />
            <TextBox margin={'0 0 0 0.5rem'} color={'text_light'}>{'Back to login'}</TextBox>
          </Button>
        </Area>
      </form>
    </Validator>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    forgotConfirm: (data) => dispatch(forgotConfirm(data)),
    notify: data => dispatch(createNotification(data)),
  }
}

export default connect(null, mapDispatchToProps)(ForgotConfirm)
