import React from 'react'

import StatefulWrapper from '_shared/components/layout/StatefulWrapper'
import Label from '_shared/components/layout/Label'
import Input from '_shared/components/element/Input'

const InputIcon = ({
  placeholder,
  name,
  field,
  status,
  value,
  disabled = false,
  required,
  requiredText,
  hideRequiredMessage,
  change,
  minHeight = '2.5rem',
  margin = '0 0.25rem 0 0.5rem',
  controlled = false,
  icon,
  ...style
}) => {
  const handleChange = (event) => change(field, event.target.value)

  return (
    <StatefulWrapper
      status={status}
      required={required}
      requiredText={requiredText}
      hideRequiredMessage={hideRequiredMessage}
      minHeight={minHeight}
      disabled={disabled}
      controlled={controlled}
      padding={'0 0.5rem 0 0'}
      {...style}
    >
      <Input
        name={name || field}
        type={'text'}
        placeholder={placeholder}
        value={value}
        change={handleChange}
        margin={margin}
        disabled={disabled}
      />
      {icon}
    </StatefulWrapper>
  )
}

const InputIconWrapped = ({ title, margin, ...rest }) => {
  return (
    <Label title={title} margin={margin}>
      <InputIcon {...rest} />
    </Label>
  )
}

const Raw = (props) => {
  return (
    <InputIcon {...props} />
  )
}

export default InputIconWrapped

export {
  Raw,
}
