import React, { useState } from 'react'
import { Raw as SideNavigation } from '_shared/components/navigation/SideNavigation'

import Area from '_shared/components/layout/Area'
import ShadowWrapper from '_shared/components/layout/ShadowWrapper'
import Row from '_shared/components/layout/Row'
import Heading from '_shared/components/layout/Heading'
import FormView from '_shared/components/layout/FormView'

import InputText from '_shared/components/input/InputText'

import InputRadio from '_shared/components/input/InputRadio'

import useDependencies from '_shared/hooks/useDependencies'

import Config from 'libs/Config'

import Container from '_shared/components/layout/Container'
import TextBox from '_shared/components/layout/TextBox'

import useLocale from '_shared/hooks/useLocale'
import InlineTextBox from '_shared/components/layout/InlineTextBox'

import PriceAdjustMatrix from 'templates/PriceAdjustMatrix'
import ConditionsTable from 'templates/ConditionsTable'

import TransferIcon from '_shared/icons/Transfer'
import OutboundIcon from '_shared/icons/Outbound'

import { getDeepLocale } from '_shared/libs/nestedDataHelpers'
import keyBy from 'lodash/keyBy'

const blockStyle = {
  padding: '0 0 1.5rem 0',
  borderBottom: `1px dashed ${Config.theme.border_thick}`,
}

const FormRule = ({
  current,
  update,
  updateSingle,
  routes,
  type,
  activeMarkets,
  activeTickets = [],
}) => {
  const { data: tickets_raw, isLoaded: tickets_loaded } = useDependencies('ticket_types')
  const { data: markets_raw, isLoaded: markets_loaded } = useDependencies('markets')

  const [ perRoute, setPerRoute ] = useState(true)

  const handleSetViewType = (field, value) => {
    // If we are setting this to true, remove all route specific pricing from effects
    setPerRoute(value)
  }

  const filteredTickets = tickets_raw.filter(ticket => activeTickets.includes(ticket.entity_id))

  const filteredMarkets = activeMarkets.map((market) => {
    return markets_raw.find(({ entity_id }) => entity_id === market.entity_id)
  })

  if (!tickets_loaded || !markets_loaded) return null

  const AdjustUI = perRoute && type !== 'event' ? AdjustPerRoute : AdjustAllRoutes

  return (
    <Area
      areas={[
        'nav',
        'content',
      ]}
      columns={2}
    >
      <ShadowWrapper area={'nav'} background={'background_module'}>
        <Row height={'7rem'} padding={'0 0 0 2rem'}>
          <Heading level={1} title={'Pricing Rule'} color={'heading_section'} />
        </Row>
      </ShadowWrapper>
      <FormView area={'content'}>
        <Area
          columns={1}
          baseColUnit={1}
          rowgap={1.5}
        >
          <Area
            areas={[
              'label . . .',
            ]}
            rawStyle={blockStyle}
          >
            <InputText
              area={'label'}
              title={'Rule name'}
              value={current.title}
              field={'title'}
              change={updateSingle}
              placeholder={'Label'}
            />
          </Area>
          <Area
            rowgap={1.5}
            columns={1}
            baseColUnit={1}
            rawStyle={blockStyle}
          >
            <Heading level={2} title={'Apply adjustment by time and days'} />
            <ConditionsTable
              data={current.conditions.date}
              update={update}
              updateSingle={updateSingle}
            />
          </Area>
          {type === 'route' && (
            <Area
              rowgap={1.5}
              columns={1}
              baseColUnit={1}
              rawStyle={blockStyle}
            >
              <Heading level={2} title={'Adjust per route?'} />
              <Row>
                <InputRadio
                  field={'display'}
                  value={perRoute}
                  change={handleSetViewType}
                  options={[
                    {
                      label: 'Apply one rule to all routes',
                      value: false,
                    },
                    {
                      label: 'Adjust per route',
                      value: true,
                    },
                  ]}
                  type={'horizontal'}
                />
              </Row>
            </Area>
          )}
          <AdjustUI
            type={type}
            routes={routes}
            filteredMarkets={filteredMarkets}
            price_adjustment={current.price_adjustment}
            tickets_filtered={filteredTickets}
            change={(key, value) => {
              updateSingle('price_adjustment', value)
            }}
            markets={markets_raw}
          />
        </Area>
      </FormView>
    </Area>
  )
}

const generateRouteLocations = (routes) => {
  return routes.map(route => {
    const {
      arrival_location,
      departure_location,
      legs,
    } = route

    const departure_entity = departure_location ? departure_location.entity_id : legs[0].departure_location.entity_id
    const arrival_entity = arrival_location ? arrival_location.entity_id : legs[legs.length - 1].arrival_location.entity_id

    return {
      departure_entity,
      arrival_entity,
      isMultiLeg: legs.length > 1,
    }
  })
}

const AdjustPerRoute = ({
  price_adjustment = [],
  tickets_filtered,
  change,
  routes,
  filteredMarkets,
}) => {
  const {
    default_locale,
  } = useLocale()

  const [selected, setSelected] = useState('default')
  const { data: locations } = useDependencies('locations')

  const lookup = locations?.reduce((list, location) => {
    list[location.entity_id] = {
      ...location,
      title: getDeepLocale(location.locales, 'title', default_locale),
    }
    return list
  }, {})

  const route_options = generateRouteLocations(routes)
    .map(({
      departure_entity,
      arrival_entity,
      isMultiLeg,
    }) => {
      return {
        departure_entity,
        arrival_entity,
        isMultiLeg,
        meta: {
          id: `${departure_entity}:${arrival_entity}`,
          start: lookup[departure_entity]?.external_id,
          end: lookup[arrival_entity]?.external_id,
          startLabel: lookup[departure_entity]?.title,
          endLabel: lookup[arrival_entity]?.title,
        },
      }
    })

  const route_hash = keyBy(route_options, 'meta.id')

  const selectedRoute = route_hash[selected] ? route_hash[selected].meta : selected

  const route_pairs = route_options
    .map(({
      departure_entity,
      arrival_entity,
    }) => ({
      departure_entity,
      arrival_entity,
    }))

  let activeRoute = null

  if (route_hash[selected]) {
    const {
      departure_entity,
      arrival_entity,
    } = route_hash[selected]

    activeRoute = {
      depart: departure_entity,
      arrive: arrival_entity,
    }
  }

  return (
    <Area
      area={'matrix'}
      areas={[
        'info',
        'chooser',
      ]}
      rawStyle={blockStyle}
      columns={1}
    >
      <Area
        area={'chooser'}
        areas={['nav grid/3']}
        columns={4}
      >
        <SideNavigation
          area={'nav'}
          options={[
            {
              title: 'Product default info',
              slug: 'default',
            },
            {
              title: '',
              slug: 'spacer',
              spacer: true,
            },
            ...route_options.map(
              (route) => {
                const {
                  isMultiLeg,
                  meta,
                } = route

                const Icon = isMultiLeg ? TransferIcon : OutboundIcon

                return {
                  title: (
                    <>
                      <InlineTextBox rawStyle={{ fontSize: 'inherit', color: 'inherit' }}>
                        {meta.startLabel}
                      </InlineTextBox>
                      <Icon fill={Config.theme.text} size={20} />
                      <InlineTextBox rawStyle={{ fontSize: 'inherit', color: 'inherit' }}>
                        {meta.endLabel}
                      </InlineTextBox>
                    </>
                  ),
                  slug: meta.id,
                }
              },
            ),
          ]}
          selected={selected}
          action={setSelected}
          noBorder
        />
        <Container area={'grid'}>
          <Heading
            level={2}
            title={selectedRoute === 'default' ? 'Product Default Price' : `Route: ${selectedRoute.startLabel} -> ${selectedRoute.endLabel} `}
          />
          <TextBox strong>{'Add pricing for tickets'}</TextBox>
          <PriceAdjustMatrix
            key={JSON.stringify({ activeRoute, route_pairs })}
            tickets={tickets_filtered}
            markets={filteredMarkets}
            routes={route_pairs}
            field={'price_adjustment'}
            change={change}
            value={price_adjustment}
            activeRoute={activeRoute}
          />
        </Container>
      </Area>
    </Area>
  )
}

const AdjustAllRoutes = ({
  type,
  price_adjustment = [],
  tickets_filtered,
  change,
  filteredMarkets,
}) => {
  return (
    <Container>
      <Heading
        level={2}
        title={type === 'event' ? 'Adjust prices' : 'Adjust all routes'}
      />
      <PriceAdjustMatrix
        tickets={tickets_filtered}
        markets={filteredMarkets}
        field={'price_adjustment'}
        change={change}
        value={price_adjustment}
      />
    </Container>
  )
}

export default FormRule
