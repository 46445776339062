import React from 'react'

import PaginatedTable from '_shared/components/navigation/PaginatedTable'

const UserPOSList = (props) => {
  const {
    data,
    create,
    edit,
    permissions,
  } = props

  return (
    <PaginatedTable
      label={'Add a new user'}
      data={data}
      columns={[
        {
          key: 'name',
          label: 'Title',
          sortable: true,
        },
        {
          key: 'department',
          label: 'Department',
          sortable: true,
        },
        {
          key: 'enabled',
          label: 'Enabled',
          sortable: false,
        },
        {
          key: 'email',
          label: 'email',
          sortable: true,
        },
      ]}
      edit={edit}
      create={create}
      permissions={permissions}
    />
  )
}

export default UserPOSList
