import React, { useState } from 'react'

import { morphism, createSchema } from 'morphism'

import Container from '_shared/components/layout/Container'
// import Area from '_shared/components/layout/Area'
import Row from '_shared/components/layout/Row'
import Heading from '_shared/components/layout/Heading'
import ActionCTA from '_shared/components/action/ActionCTA'
import Card from '_shared/components/layout/Card'
import CardSelected from '_shared/components/notification/CardSelected'
import TextBox from '_shared/components/layout/TextBox'
import { mapDefaults } from '_shared/libs/mapToSchema'

import {
  getToday,
  stampToDateISO,
} from 'libs/date'

import useLocale from '_shared/hooks/useLocale'

import api from 'api/reports'

const getSpec = createSchema(
  {
    type: 'type',
    markets: 'markets',
    services: 'services',
    products: 'products',
    channels: 'channels',
    statuses: 'statuses',
    sources: 'sources',
    location_id: 'location_id',
    'service-types': 'service-types',
    'service-date-start': 'service-date-start',
    'service-date-end': 'service-date-end',
    'booking-date-start': 'booking-date-start',
    'booking-date-end': 'booking-date-end',
    'service-time-start': 'service-time-start',
    'service-time-end': 'service-time-end',
    'external-order-type': 'external-order-type',
    format: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  },
  mapDefaults({
    'type': 'manifest',
    'markets': [],
    'services': [],
    'products': [],
    'channels': [],
    'statuses': [],
    'sources': [],
    'service-types': [],
    'service-date-start': stampToDateISO(getToday()),
    'service-date-end': stampToDateISO(getToday()),
    'external-order-type': [],
    'format': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  }),
)

const Reports = ({
  value,
}) => {
  return (
    <Container>
      <Heading level={1} title={'Quick Reports'} margin={'0 0 1.5rem 0'} />
      <Row type={'start'}>
        {value.map((report, index) => {
          const {
            meta,
            fields,
          } = report

          return (
            <ReportCard
              key={index}
              meta={meta}
              fields={fields}
            />
          )
        })}
      </Row>
    </Container>
  )
}

const ReportCard = ({
  meta,
  fields,
}) => {
  const {
    default_locale,
  } = useLocale()

  const [
    loading,
    setLoading,
  ] = useState(false)

  const handleSubmit = (record) => async () => {
    const {
      format,
      type,
      ...rest
    } = record

    const output = Object.keys(rest)
      .reduce((acc, cur) => {
        if (Array.isArray(rest[cur])) {
          if (rest[cur].length > 0) acc[cur] = rest[cur]
        } else {
          if (rest[cur] !== null && rest[cur] !== '') acc[cur] = rest[cur]
        }

        return acc
      }, {})

    setLoading(true)

    try {
      await api.getReport(output, format, type, default_locale)

      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  return (
    <Card>
      <CardSelected>
        <Heading level={2} title={meta.title} align={'center'} />
        <TextBox textAlign={'center'}>{meta.text}</TextBox>
        <ActionCTA disabled={loading} label={'Download report'} change={handleSubmit(morphism(getSpec, fields))} margin={'2rem auto 0 auto'} />
      </CardSelected>
    </Card>
  )
}

export default Reports
