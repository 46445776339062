import React from 'react'
import {
  connect,
} from 'react-redux'

import styles from './auth.scss'

import Logo from 'icons/Logo'

import Routes from './Routes'

const Auth = () => {
  return (
    <div className={styles.layout}>
      <div className={styles.view_content}>
        <div className={styles.logo}>
          <Logo />
        </div>
        <Routes />
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  const {
    loginState,
  } = state.ui

  return {
    loginState,
  }
}

export default connect(mapStateToProps)(Auth)
