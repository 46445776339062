import React, { useEffect, useState } from 'react'
import {
  connect,
} from 'react-redux'

import {
  BrowserRouter as Router,
} from 'react-router-dom'

import {
  checkTokenAndLogin,
} from 'actions/user'

import storeFromServer from 'actions/coreData'

import './app.scss'

import AppStatus from './AppStatus'
import ToastManager from '_shared/components/notification/Toasts'

import Splash from './Splash'
import Auth from './Auth'
import Main from './Main'

import { ReactQueryDevtools } from 'react-query/devtools'

const AppRoot = ({
  validate,
  setCore,
  authenticated,
}) => {
  const [
    complete,
    setComplete,
  ] = useState(false)

  useEffect(() => {
    validate()
      .then(() => {
        return setCore()
      })
      .then(() => {
        setComplete(true)
      })
      .catch(error => {
        console.log('Token test failed', error)

        setComplete(true)
      })
  }, [setCore, validate])

  if (!complete) {
    return (
      <Splash />
    )
  } else {
    return (
      <Router>
        <AppStatus>
          {!authenticated && (
            <Auth />
          )}
          {authenticated && (
            <Main />
          )}
          <ToastManager />
        </AppStatus>
        <ReactQueryDevtools initialIsOpen={false} />
      </Router>
    )
  }
}

const mapStateToProps = ({
  user,
}) => {
  return {
    authenticated: user.authenticated,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setCore: (group) => dispatch(storeFromServer()),
    validate: () => dispatch(checkTokenAndLogin()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppRoot)
