import React from 'react'

import Area from '_shared/components/layout/Area'
import FormView from '_shared/components/layout/FormView'
import TextBox from '_shared/components/layout/TextBox'

import Heading from '_shared/components/layout/Heading'
import SectionWrapper from '_shared/components/layout/SectionWrapper'

import InputRadio from '_shared/components/input/InputRadio'
import InputISOInterval from '_shared/components/input/InputISOInterval'

import PurchaseLimit from 'templates/PurchaseLimit'

import ChannelMatrix from 'templates/ChannelMatrixActual'

const FormLimits = ({
  current,
  update,
  updateSingle,
}) => {
  const handleValueSwitch = () => {
    if (current.sales_limitations.date.before_offset_from_now === null) {
      updateSingle('sales_limitations.date.before_offset_from_now', 'P14D')
    } else {
      updateSingle('sales_limitations.date.before_offset_from_now', null)
    }
  }

  return (
    <FormView>
      <Area
        areas={[
          'section_early',
          'early',
          'section_limits',
          'limits',
          'section_dates',
          'dates',
          'section_channels',
          'channels',
        ]}
        rowgap={1.5}
      >
        <SectionWrapper area={'section_early'}>
          <Heading level={1} title={'Early bird'} />
        </SectionWrapper>
        <Area
          area={'early'}
          areas={[
            'chooser',
            'interval . . .',
          ]}
          rowgap={1.5}
        >
          <InputRadio
            area={'chooser'}
            type={'horizontal'}
            field={'sales_limitations.date.before_offset_from_now'}
            value={current.sales_limitations.date.before_offset_from_now === null}
            options={[
              {
                value: true,
                label: 'No early bird',
              },
              {
                value: false,
                label: 'Set up early bird time period',
              },
            ]}
            change={handleValueSwitch}
          />
          {current.sales_limitations.date.before_offset_from_now !== null && (
            <InputISOInterval
              area={'interval'}
              title={'Early bird period'}
              field={'sales_limitations.date.before_offset_from_now'}
              value={current.sales_limitations.date.before_offset_from_now}
              change={updateSingle}
            />
          )}
        </Area>
        <SectionWrapper area={'section_limits'}>
          <Heading level={1} title={'Purchase quantity limit'} />
        </SectionWrapper>
        <Area
          area={'limits'}
          areas={[
            'info',
            'selector/2 .',
          ]}
          columns={4}
          rowgap={1}
        >
          <TextBox strong area={'info'}>{'Limit the number of times this combo can be applied.'}</TextBox>
          <PurchaseLimit
            area={'selector'}
            current={current}
            change={update}
          />
        </Area>
        <SectionWrapper area={'section_channels'}>
          <Heading level={1} title={'Markets and channels'} />
        </SectionWrapper>
        <Area
          area={'channels'}
          areas={[
            'info',
            'selector',
          ]}
          rowgap={1}
        >
          <TextBox strong area={'info'}>{'Limit when this product to be purchased through certain channels.'}</TextBox>
          <ChannelMatrix
            area={'selector'}
            current={current}
            change={updateSingle}
          />
        </Area>
      </Area>
    </FormView>
  )
}

export default FormLimits
