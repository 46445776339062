import React from 'react'

import CTAButton from '_shared/components/element/CTAButton'
import LockedBadge from '_shared/components/element/LockedBadge'

const ActionCTA = ({
  type = 'default',
  disabled = false,
  change,
  label,
  minHeight = '2.5rem',
  locked = false,
  ...style
}) => {
  const states = {
    default: 'button',
    important: 'button_success',
    unimportant: 'button_cancel',
    caution: 'button_stop',
    disabled: 'button_disabled',
    frozen: 'button_frozen',
  }

  if (disabled || locked) type = 'disabled'

  return (
    <CTAButton background={states[type]} minHeight={minHeight} change={change} disabled={disabled} {...style}>
      {label}
      {locked && (
        <LockedBadge rawStyle={{ position: 'absolute', top: '-0.5rem', right: '-0.5rem' }} />
      )}
    </CTAButton>
  )
}

export default ActionCTA
