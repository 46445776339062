import React, { Component } from 'react'

import Container from '_shared/components/layout/Container'

import MonthRow from './MonthRow'
import DayBar from './DayBar'
import Calendar from './Calendar'

import {
  getToday,
  splitDate,
  stampFromYM,
  stampToDateISO,
} from 'libs/date'

class DatePicker extends Component {
  constructor (props) {
    super(props)

    const {
      month,
      year,
    } = splitDate(props.date || getToday())

    this.state = {
      display: {
        month,
        year,
      },
    }
  }

  componentDidUpdate (prevProps) {
    if (prevProps.date !== this.props.date) {
      const {
        month,
        year,
      } = splitDate(this.props.date || getToday())

      this.setState({
        display: {
          month,
          year,
        },
      })
    }
  }

  update = (value) => {
    this.props.update(value)
  }

  changeMonth = (increase) => {
    let {
      month,
      year,
    } = this.state.display

    if (increase) {
      if ((month + 1) > 12) {
        month = 1
        year += 1
      } else {
        month += 1
      }
    } else {
      if ((month - 1) < 1) {
        month = 12
        year -= 1
      } else {
        month -= 1
      }
    }

    this.setState({
      display: {
        month,
        year,
      },
    })
  }

  render () {
    const {
      month,
      year,
    } = this.state.display

    const monthStamp = stampFromYM(year, month - 1)

    return (
      <Container>
        <MonthRow
          date={monthStamp}
          change={this.changeMonth}
        />
        <DayBar
          startsOnMonday
        />
        <Calendar
          disabled={this.props.disabled}
          date={stampToDateISO(monthStamp)}
          selected={[this.props.date]}
          action={this.update}
        />
      </Container>
    )
  }
}

export default DatePicker
