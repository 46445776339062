import React from 'react'

import FormView from '_shared/components/layout/FormView'
import useLocale from '_shared/hooks/useLocale'
import Area from '_shared/components/layout/Area'
import Heading from '_shared/components/layout/Heading'
import TextBox from '_shared/components/layout/TextBox'
import InputSelect from '_shared/components/input/InputSelect'
import InputTagSelector from '_shared/components/input/InputTagSelector'

import useDependencies from '_shared/hooks/useDependencies'

import {
  getDeepLocale,
} from '_shared/libs/nestedDataHelpers'

import CapacityAreas from '../../BuildingBlocks/subviews/Layouts/form/CapacityAreas'
import InputCheckBox from '_shared/components/input/InputCheckBox'

const FormLayout = ({
  current,
  update,
  updateSingle,
}) => {
  // const [
  //   layout,
  //   setLayout
  // ] = useState(null)

  const {
    default_locale,
  } = useLocale()

  const { data: ticket_groups } = useDependencies('ticket_groups', data => data.map(({ entity_id, locales }) => {
    return {
      label: getDeepLocale(locales, 'title', default_locale),
      value: entity_id,
    }
  }))

  const { data: venues } = useDependencies('venues', 
    (data) => data.filter(
      (venue) => current.type === 'event' ? venue.type === 'venue' : venue.type === 'vehicle',
    ),
  )

  const venue_options = venues.map(({ entity_id, locales }) => {
    return {
      label: getDeepLocale(locales, 'title', default_locale),
      value: entity_id,
    }
  })

  const handleVenueChange = (field, value) => {
    update([
      {
        field,
        value,
      },
      {
        field: 'compatible_ticket_type_groups',
        value: [],
      },
    ])
  }

  const handleMulti = (field, payload) => updateSingle(field, payload
    .map(entity_id => ({ entity_id })))

  return (
    <FormView>
      <Area
        areas={[
          'section',
          'venue',
          'attendee',
          'layout',
          'single_booking',
        ]}
        rowgap={1.5}
      >
        <Heading area={'section'} level={2} title={'Venue'} />
        <Area
          area={'venue'}
          areas={[
            'info',
            'field . .',
          ]}
          rowgap={0.5}
        >
          <TextBox strong area={'info'}>{'1. Select the venue for this Event:'}</TextBox>
          <InputSelect
            area={'field'}
            title={'Venue'}
            field={'venue.entity_id'}
            value={current.venue.entity_id}
            placeholder={'Choose a Venue'}
            options={venue_options}
            change={handleVenueChange}
            margin={'0 0 0 1rem'}
          />
        </Area>
        <Area
          area={'attendee'}
          areas={[
            'info',
            'field . .',
          ]}
          rowgap={0.5}
        >
          <TextBox strong area={'info'}>{'2. What can attend this service'}</TextBox>
          <InputTagSelector
            area={'field'}
            field={'compatible_ticket_type_groups'}
            value={current.compatible_ticket_type_groups.map(item => item.entity_id)}
            options={ticket_groups}
            change={handleMulti}
            margin={'0 0 0 1rem'}
          />
        </Area>
        <Area
          area={'single_booking'}
          areas={[
            'info',
            'field . .',
          ]}
          rowgap={0.5}
        >
          <TextBox strong area={'info'}>{'3. Single booking only'}</TextBox>
          <InputCheckBox
            area={'field'}
            title={'Single booking only'}
            field={'single_booking_only'}
            value={current.single_booking_only}
            change={updateSingle}
            margin={'0 0 1rem 0'}
          />
        </Area>
        <CapacityAreas
          area={'layout'}
          field={'layout.areas'}
          data={current.layout.areas}
          change={updateSingle}
        />
      </Area>
    </FormView>
  )
}



export default FormLayout
