import React from 'react'

import Area from '_shared/components/layout/Area'
import FormView from '_shared/components/layout/FormView'
import Row from '_shared/components/layout/Row'
import TextBox from '_shared/components/layout/TextBox'

import Heading from '_shared/components/layout/Heading'
import SectionWrapper from '_shared/components/layout/SectionWrapper'

import InputRadio from '_shared/components/input/InputRadio'
import InputText from '_shared/components/input/InputText'

const FormActivation = ({
  current,
  update,
  updateSingle,
}) => {
  const handleValueSwitch = () => {
    update([
      {
        field: 'auto_apply',
        value: !current.auto_apply,
      },
      {
        field: 'promo_code',
        value: null,
      },
    ])
  }

  return (
    <FormView>
      <Area
        areas={[
          'title',
          'content',
        ]}
        rowgap={1.5}
      >
        <SectionWrapper area={'title'}>
          <Heading level={1} title={'Apply automatically or promo code'} />
        </SectionWrapper>
        <Area
          area={'content'}
          areas={[
            'info',
            'chooser',
            'code . . .',
          ]}
          rowgap={1.5}
        >
          <TextBox strong area={'info'} noMargin>{'When customer has compatible items in their basket, the discount will be automatically applied.'}</TextBox>
          <InputRadio
            area={'chooser'}
            type={'horizontal'}
            field={'sales_limitations.date.before_offset_from_now'}
            value={current.auto_apply}
            options={[
              {
                value: true,
                label: 'Yes, apply automatically',
              },
              {
                value: false,
                label: 'No, promo code required',
              },
            ]}
            change={handleValueSwitch}
          />
          {!current.auto_apply && (
            <Row area={'code'}>
              <InputText
                area={'code'}
                title={'Define promo code'}
                field={'promo_code'}
                value={current.promo_code}
                placeholder={'E.g. SUMMER20'}
                change={updateSingle}
              />
            </Row>
          )}
        </Area>
      </Area>
    </FormView>
  )
}

export default FormActivation
