"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.salesChannelList = exports.SalesChannel = void 0;
var SalesChannel;
(function (SalesChannel) {
    SalesChannel[SalesChannel["POINT_OF_SALE"] = 1] = "POINT_OF_SALE";
    SalesChannel[SalesChannel["WEB_DIRECT"] = 2] = "WEB_DIRECT";
    SalesChannel[SalesChannel["RESERVATIONS_PORTAL"] = 3] = "RESERVATIONS_PORTAL";
    SalesChannel[SalesChannel["ONLINE_TRAVEL_AGENT"] = 4] = "ONLINE_TRAVEL_AGENT";
    SalesChannel[SalesChannel["VOUCHER"] = 5] = "VOUCHER";
    SalesChannel[SalesChannel["TRADE_PARTNER"] = 6] = "TRADE_PARTNER";
    SalesChannel[SalesChannel["APP"] = 7] = "APP";
    SalesChannel[SalesChannel["SELF_SERVICE"] = 8] = "SELF_SERVICE";
})(SalesChannel = exports.SalesChannel || (exports.SalesChannel = {}));
exports.salesChannelList = [1, 2, 3, 4, 5, 6, 7, 8];
