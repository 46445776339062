import React from 'react'

import Selector from '../Selector'

const ChooserLocationExternalID = ({
  data,
  change,
}) => {
  return (
    <Selector
      field={'location_id'}
      label={'Filter by location'}
      data={data}
      change={change}
      dependency={'locations'}
      valueKey={'external_id'}
    />
  )
}

export default ChooserLocationExternalID
