import React, { Fragment } from 'react'

import styles from './navigator.scss'

import InlineTextBox from '_shared/components/layout/InlineTextBox'

import LockIcon from 'icons/Locked'

import Config from 'libs/Config'

const LinkContent = ({ item, locked, collapsed }) => {
  const {
    icon,
    display,
  } = item

  const Icon = icon

  return (
    <Fragment>
      {!collapsed && (
        <Icon
          fill={locked ? Config.theme.text_disabled : Config.theme.nav_icon}
          size={20}
        />
      )}
      <InlineTextBox
        gridAlign={'stretch'}
        textAlign={'center'}
        color={'nav_text'}
        rawStyle={locked ? { color: 'RGBA(var(--theme_nav_text), 0.5)' } : { color: 'RGB(var(--theme_nav_text))' }}
      >
        {display}
      </InlineTextBox>
      {locked && (
        <div className={styles.locked}>
          <LockIcon
            fill={Config.theme.text_disabled}
            size={16}
          />
        </div>
      )}
    </Fragment>
  )
}

export default LinkContent
