import React from 'react'

import NavIcon from './NavIcon'

import View from '_shared/components/layout/View'
import Heading from '_shared/components/layout/Heading'

import SideNavigation from '_shared/components/navigation/SideNavigation'

import Cache from './subviews/Cache'
import Localisation from './subviews/Localisation'
import Markets from './subviews/Markets/Main'

const Settings = () => {
  const options = [
    {
      title: 'Languages & Currency',
      view: Localisation,
      slug: 'localisation',
    },
    {
      title: 'Markets',
      view: Markets,
      slug: 'marketing',
    },
    {
      title: 'Cache',
      view: Cache,
      slug: 'cache',
    },
  ]

  return (
    <View>
      <Heading
        level={'section'}
        title={'System administration'}
      />
      <SideNavigation
        options={options}
      />
    </View>
  )
}

export default Settings

export {
  NavIcon,
}