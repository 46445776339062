import React from 'react'

import {
  getSpec,
  valid as valid_spec,
  handlers,
} from './Context'

import { StateTabbedHeader as TabbedHeader }  from '_shared/components/navigation/TabbedHeader'

import FormUser from './FormUser'

import SlideForm from 'templates/SlideForm'

const options = [
  {
    label: 'Description',
    view: FormUser,
    slug: 'description',
  },
]

const Consumer = () => {
  return (
    <SlideForm
      options={options}
      data_spec={getSpec}
      valid_spec={valid_spec}
      handlers={handlers}
      mountKey={'record'}
      permissionsKey={'user'}
      render={({
        status,
        current,
        valid,
        updateSingle,
      }) => {
        return (
          <TabbedHeader
            title={'User management'}
            options={options}
            passProps={{
              status,
              current,
              valid,
              updateSingle,
            }}
          />
        )
      }}
    />
  )
}

export default Consumer
