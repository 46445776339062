import React, { Fragment, useState } from 'react'

import FormView from '_shared/components/layout/FormView'
import Area from '_shared/components/layout/Area'
import Row from '_shared/components/layout/Row'

import InputCheckBox from '_shared/components/input/InputCheckBox'
import InputDate from '_shared/components/input/InputDate'
import ActionCTA from '_shared/components/action/ActionCTA'
import ScheduleConsumer from '../../ScheduleWizard/form/Consumer'
import InstanceConsumer from '../../Instances/form/Consumer'

import ScheduleList from './ScheduleList'

import isAfter from 'date-fns/is_after'
import isBefore from 'date-fns/is_before'

const FormSchedule = ({
  current,
  updateSingle,
}) => {
  const [
    showArchived,
    setShowArchived,
  ] = useState(false)

  const [
    publish,
    setSub,
  ] = useState(false)

  const [
    instance,
    setInstance,
  ] = useState(false)

  const [
    record,
    setRecord,
  ] = useState(null)

  const openInstance = (record = null) => {
    setRecord(record)

    setInstance(true)
  }

  const openSchedule = () => {
    setSub(true)
  }

  const closeForm = () => {
    setSub(false)
    setInstance(false)
  }

  const [
    startDate,
    setStartDate,
  ] = useState(new Date().toISOString().split('T')[0])

  const [
    endDate,
    setEndDate,
  ] = useState(new Date().toISOString().split('T')[0])

  const checkDate = (field, value) => {
    if (field === 'endDate') {
      if (isBefore(value, startDate)) {
        setStartDate(value)
        setEndDate(value)
      } else {
        setEndDate(value)
      }
    }

    if (field === 'startDate') {
      if (isAfter(value, endDate)) {
        setStartDate(value)
        setEndDate(value)
      } else {
        setStartDate(value)
      }
    }
  }

  return (
    <Fragment>
      <ScheduleConsumer
        mount={publish}
        close={closeForm}
        service={current.entity_id}
        type={current.type}
        operator_type={current.operator_type}
      />
      <InstanceConsumer
        mount={instance}
        close={closeForm}
        record={record}
        service={current.entity_id}
        type={current.type}
        operator_type={current.operator_type}
      />
      <FormView>
        <Area
          areas={[
            'layout',
            'templates',
          ]}
          rowgap={1.5}
        >
          <Row type={'spaced'} area={'layout'}>
            <Row crossAxis={'flex-end'}>
              <ActionCTA change={() => openSchedule()} label={'Add Departures'} />
            </Row>
            <Row type={'end'} crossAxis={'center'}>
              <InputDate
                title={'Start date'}
                field={'startDate'}
                value={startDate}
                placeholder={'Start'}
                change={checkDate}
                width={'10rem'}
              />
              <InputDate
                title={'End date'}
                field={'endDate'}
                value={endDate}
                placeholder={'End'}
                change={checkDate}
                width={'10rem'}
              />
              <InputCheckBox
                title={'Show archived'}
                field={'archived'}
                value={showArchived}
                change={(field, value) => setShowArchived(value)}
              />
            </Row>
          </Row>
          <ScheduleList
            type={current.type}
            area={'templates'}
            edit={openInstance}
            service={current.entity_id}
            startDate={startDate}
            endDate={endDate}
            operator_type={current.operator_type}
          />
        </Area>
      </FormView>
    </Fragment>
  )
}

export default FormSchedule
