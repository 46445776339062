import React from 'react'

import Area from '_shared/components/layout/Area'
import Heading from '_shared/components/layout/Heading'

import ChooserLocationExternalID from '../choosers/ChooserLocationExternalID'

const LocationFilterExternalID = ({
  data,
  change,
}) => {
  return (
    <Area
      areas={[
        'title',
        'location . .',
      ]}
      columns={3}
      rowgap={1}
    >
      <Heading
        area={'title'}
        level={3}
        title={'Location'}
      />
      <ChooserLocationExternalID
        area={'location'}
        data={data}
        change={change}
      />
    </Area>
  )
}

export default LocationFilterExternalID
