import React, { useCallback, useMemo, useState, useEffect, useRef } from 'react'

import StatefulWrapper from '_shared/components/layout/StatefulWrapper'
import Label from '_shared/components/layout/Label'
import Input from '_shared/components/element/Input'
import InlineTextBox from '_shared/components/layout/InlineTextBox'

import Row from '_shared/components/layout/Row'

import DropDown from '_shared/components/element/DropDown'
import { Raw as InputSelect } from '_shared/components/input/InputSelect'
import { Raw as InputInteger } from '_shared/components/input/InputInteger'

import useParentDistance from '_shared/hooks/useParentDistance'

import upperFirst from 'lodash/upperFirst'

import * as duration from 'duration-fns'

const timeOptions = [
  'years',
  'months',
  'weeks',
  'days',
  'hours',
  'minutes',
  'seconds',
]

const generateISOInterVal = (value, quantifier) => {
  return duration.toString({ [quantifier]: value })
}

const parseISOInterval = (value, defaultVal = 'days') => {
  const parsed = duration.parse(value)

  const response = Object.keys(parsed)
    .reduce((output, key) => {
      if (parsed[key] > 0) {
        output.value = parsed[key]
        output.quantifier = key
      }

      return output
    }, {
      value: 0,
      quantifier: defaultVal,
    })

  return response
}

const InputISOInterval = ({
  placeholder,
  name,
  field,
  options = ['years', 'months', 'weeks', 'days', 'hours', 'minutes'],
  optionExtension = null,
  status,
  value,
  disabled = false,
  required,
  requiredText,
  hideRequiredMessage,
  change,
  minHeight = '2.5rem',
  margin = '0 0.25rem 0 0.5rem',
  controlled = false,
  split = false,
  ...style
}) => {
  const container = useRef(null)

  const decoratedOptions = useMemo(() => {
    return timeOptions
      .filter(timeOption => options.includes(timeOption))
      .map(option => {
        return {
          value: option,
          label: upperFirst(optionExtension === null ? option : `${option} ${optionExtension}`),
        }
      })
  }, [options, optionExtension])

  const {
    value: internal,
    quantifier,
  } = parseISOInterval(value, decoratedOptions[0].value)

  useEffect(() => {
    if (!options.includes(quantifier)) {
      console.error(`${quantifier} in ${field} is not allowed. Reseting to 0`)
      change(field, 'P0D')
      setHeld('days')
    }
  }, [change, field, options, quantifier])

  const [
    held,
    setHeld,
  ] = useState(quantifier)

  const [
    open,
    setOpen,
  ] = useState(false)

  const {
    maxHeight,
    calcMaxHeight,
  } = useParentDistance(container)

  const handleChange = value => change(field, value)

  const handleOpen = useCallback(() => {
    calcMaxHeight()

    setOpen(true)
  }, [calcMaxHeight])

  const forceClose = useCallback(() => {
    setOpen(false)
  }, [])

  const displayQuantifier = internal === 0 ? held : quantifier

  const updateQuantifier = (quantifier) => {
    handleChange(generateISOInterVal(internal, quantifier))
    setHeld(quantifier)
  }

  const updateValue = (event) => {
    const value = Number(event.target.value.replace(/[^0-9]/g, ''))

    handleChange(generateISOInterVal(value, displayQuantifier))
  }

  const label = decoratedOptions.find(item => displayQuantifier === item.value)?.label || 'Undefined'

  if (split) {
    return (
      <Row minHeight={minHeight}>
        <InputInteger
          placeholder={placeholder}
          name={name}
          field={field}
          value={internal}
          change={(field, value) => handleChange(generateISOInterVal(value, displayQuantifier))}
          status={status}
          required={required}
          requiredText={requiredText}
          hideRequiredMessage={hideRequiredMessage}
          minHeight={minHeight}
          disabled={disabled}
          controlled={controlled}
          width={'6rem'}
          noFlex
        />
        <InputSelect
          placeholder={'Choose Duration'}
          options={decoratedOptions}
          value={displayQuantifier}
          change={(field, value) => updateQuantifier(value)}
          disabled={disabled}
          legacy={false}
          minHeight={minHeight}
        />
      </Row>
    )
  }

  return (
    <StatefulWrapper
      status={status}
      required={required}
      requiredText={requiredText}
      getRef={container}
      hideRequiredMessage={hideRequiredMessage}
      minHeight={minHeight}
      disabled={disabled}
      controlled={controlled}
      {...style}
    >
      <Input
        name={name || field}
        type={'text'}
        placeholder={placeholder}
        value={internal}
        change={updateValue}
        margin={margin}
        disabled={disabled}
        focus={handleOpen}
        blur={forceClose}
      />
      <InlineTextBox margin={'0 0.5rem 0 auto'} color={'text_success'}>{label}</InlineTextBox>
      {open && (
        <DropDown
          options={decoratedOptions}
          change={updateQuantifier}
          value={displayQuantifier}
          cancel={forceClose}
          focusOnMount={false}
          maxHeight={maxHeight}
        />
      )}
    </StatefulWrapper>
  )
}

const InputISOIntervalWrapped = ({
  title,
  margin,
  ...rest
}) => {
  return (
    <Label title={title} margin={margin}>
      <InputISOInterval {...rest} />
    </Label>
  )
}

const Raw = (props) => {
  return (
    <InputISOInterval {...props} />
  )
}


export default InputISOIntervalWrapped

export {
  Raw,
}
