import React from 'react'

import Row from '_shared/components/layout/Row'
import InlineTextBox from '_shared/components/layout/InlineTextBox'
import Button from '_shared/components/element/Button'

import CalPrev from 'icons/CalPrev'
import CalNext from 'icons/CalNext'

import {
  getMonthInfo,
} from 'libs/date'

import colors from 'config/colors.json'

const MonthRow = (props) => {
  const {
    date,
    change,
  } = props

  const monthStrings = getMonthInfo(date)

  return (
    <Row
      type={'center'}
      padding={'1rem 2rem'}
      rawStyle={{ borderBottom: '1px solid RGB(var(--theme_text))'}}
    >
      <Button
        noFlex
        change={(event) => {
          event.preventDefault()

          change(false)
        }}
      >
        <CalPrev
          fill={colors.color_text}
          size={16}
        />
      </Button>
      <InlineTextBox
        noFlex
        strong
        size={1}
        width={'75%'}
        texAlign={'center'}
      >
        {`${monthStrings.short} ${monthStrings.year}`}
      </InlineTextBox>
      <Button
        noFlex
        change={(event) => {
          event.preventDefault()

          change(true)
        }}
      >
        <CalNext
          fill={colors.color_text}
          size={16}
        />
      </Button>
    </Row>
  )
}

export default MonthRow
