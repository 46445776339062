import React from 'react'

import Column from '_shared/components/layout/Column'

import LockedIcon from 'icons/Locked'

import colors from 'config/colors'

const LockedBadge = ({
  ...style
}) => {
  return (
    <Column type={'centered'} noFlex width={'1.5rem'} height={'1.5rem'} background={'text'} borderRadius={'100%'} {...style}>
      <LockedIcon
        fill={colors.color_text_light}
        size={14}
      />
    </Column>
  )
}

export default LockedBadge
