const reports = [
  {
    disabled: false,
    title: 'Sales report',
    type: 'sales',
    description: 'Report outlining total sales across channel, service, product and date range. Filter the results to get a more focused sales report.',
    form: [
      'metaFilter',
      'productFilter',
      'locationFilter',
      'serviceDate',
      'bookingDate',
      'fileFormat',
    ],
    layout: [
      'metaFilter',
      'productFilter',
      'locationFilter',
      'serviceDate',
      'bookingDate',
      'fileFormat',
    ],
  },
  {
    disabled: false,
    title: 'Functions manifest',
    type: 'manifest',
    description: 'Report outlining total sales across channel, service, product and date range. Filter the results to get a more focused sales report.',
    form: [
      'metaFilter',
      'productFilter',
      'serviceDate',
      'bookingDate',
      'fileFormat',
    ],
    layout: [
      'metaFilter',
      'productFilter',
      'serviceDate',
      'bookingDate',
      'fileFormat',
    ],
  },
  {
    disabled: false,
    title: 'Yield report',
    type: 'yield',
    description: 'Report outlining total sales across channel, service, product and date range. Filter the results to get a more focused sales report.',
    form: [
      'services',
      'serviceTypes',
      'yieldServiceDate',
      'fileFormat',
    ],
    layout: [
      'services',
      'serviceTypes',
      'yieldServiceDate',
      'fileFormat',
    ],
  },
  {
    disabled: false,
    title: 'External Orders report',
    type: 'external-order',
    description: 'Report outlining sales made externally (eg via POS)',
    form: [
      'bookingDate',
      'externalOrderType',
      'locationFilterExternalID',
      'fileFormat',
    ],
    layout: [
      'bookingDate',
      'externalOrderType',
      'locationFilterExternalID',
      'fileFormat',
    ],
  },
]

export default reports
