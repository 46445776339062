import React, { Fragment } from 'react'

import Navigator from '../Navigator'

import Routes from './Routes'

const Main = () => {
  return (
    <Fragment>
      <Navigator />
      <Routes />
      <div id={'full'} />
      <div id={'slide'} />
      <div id={'portal'} />
    </Fragment>
  )
}

export default Main
