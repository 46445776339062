import set from 'lodash/set'
import get from 'lodash/get'
import has from 'lodash/has'
import toPath from 'lodash/toPath'

import { morphism } from 'morphism'

const generateNested = (spec, collection, key) => collection
  .map(code => ({
    [key]: code,
    ...spec,
  }))

const mapKeys = (schema, source) => {
  return Object.keys(schema)
    .reduce((output, key) => {
      output[key] = source.hasOwnProperty(key) && source[key] !== null ? source[key] : schema[key]

      return output
    }, {})
}

const mapLocales = (schema, source = [], locales = []) => {
  return generateNested(schema, locales, 'locale')
    .map(row => {
      const found = source.find(item => item.locale === row.locale)

      return found ? mapKeys(row, found) : row
    })
}

const mapCurrencies = (schema, source = [], currencies) => {
  return generateNested(schema, currencies, 'currency')
    .map(row => {
      const found = source.find(item => item.currency === row.currency)

      return found ? mapKeys(row, found) : row
    })
}

const translateNestedKey = (data, path, key, field = 'locale') => {
  const indexes = path
    .reduce((acc, cur, index) => {
      if (cur === key) acc.push(index)
      return acc
    }, [])

  indexes.forEach(index => {
    if (path[index + 1]) {
      if (isNaN(path[index + 1])) {
        const haystack = get(data, path.slice(0, index + 1))
        if (!haystack) return

        const valueIndex = haystack.findIndex(item => item[field] === path[index + 1])

        if (valueIndex !== -1) path[index + 1] = valueIndex
      }
    }
  })
}

const update = (data, field, value) => {
  const path = toPath(field)

  translateNestedKey(data, path, 'locales')

  if (path[0] === 'locales') {
    try {
      const index = data.locales.findIndex(item => item.locale === path[1])
      if (index !== -1) path[1] = index
    } catch(error) {
      console.log('LOCALES DO NOT EXIST AT THIS LEVEL', data, field, value)
    }

  }

  if (!has(data, path)) return null
  return set(data, path, value)
}

const mapToSchema = (locales, currencies, getSchema) => (data) => {
  const data_spec = getSchema(locales, currencies)

  return morphism(data_spec, data)
}

const mapDefaults = (defaults) => ({
  undefinedValues: {
    strip: true,
    default: (source, key) => {
      return defaults.hasOwnProperty(key) ? defaults[key] : null
    },
  },
})

const mapToArraySubSchema = (getSchema, locales = [], currencies = []) => (value, source) => {
  if (!value) return []
  return value.map(record => morphism(getSchema(locales, currencies), record))
}

export default mapToSchema

export {
  update,
  mapLocales,
  mapCurrencies,
  mapDefaults,
  mapToArraySubSchema,
}
