import React, { Component, useState, Fragment } from 'react'

import styles from './carouselcontroller.scss'

const CarouselWrapper = ({
  render,
  children,
  slides,
  slideStyling,
  styling,
}) => {
  const [ current, setCurrent ] = useState(0)

  const getChildren = () => {
    const className = styles[`slide_${slides}`]
    const distance = 100 / slides

    // Slides per movement = props.slides

    const cards = React.Children.map(children, (child, index) => {
      const style = {}

      if (index === 0) style.marginLeft = `-${current * distance}%`

      const renderChild = index >= (current - slides) && index <= (current + slides)

      return (
        <div
          key={index}
          className={[className, slideStyling].join(' ')}
          style={style}
        >
          {renderChild ? child : null}
        </div>
      )
    })

    return (
      <div className={[styles.caddy, styling].join(' ')}>
        {cards}
      </div>
    )
  }

  const handleAdvance = (direction) => {
    const count = React.Children.count(children)

    let advance = current

    if (count > slides) {
      if (direction === 'left' && (current - 1) > -1) {
        advance -= 1
      }

      if (direction === 'right' && (current + 1) < ((count + 1) - slides)) {
        advance += 1
      }
    }

    setCurrent(advance)
  }

  const count = React.Children.count(children)

  const leftActive = count > slides && (current) !== 0

  const rightActive = count > slides && (current) !== ((count) - slides)

  const position = `${current + 1} of ${count}`

  return (
    <Fragment>
      {render({
        advance: handleAdvance,
        leftActive,
        rightActive,
        cards: getChildren(),
        position,
        current,
        count,
      })}
    </Fragment>
  )
}

export default CarouselWrapper
