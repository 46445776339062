import React from 'react'

const StarIcon = (props) => {
  const {
    size,
    fill,
    rawStyle,
  } = props

  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={size} height={size} style={rawStyle} viewBox='0 0 24 24'>
      <path d='M0 0h24v24H0z' fill='none' />
      <path d='M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z' fill={fill} />
      <path d='M0 0h24v24H0z' fill='none' />
    </svg>
  )
}

export default StarIcon
