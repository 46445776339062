import React, { useState } from 'react'

import { morphism } from 'morphism'

import Area from '_shared/components/layout/Area'
import FormView from '_shared/components/layout/FormView'
import Heading from '_shared/components/layout/Heading'
import InlineTextBox from '_shared/components/layout/InlineTextBox'
import SectionWrapper from '_shared/components/layout/SectionWrapper'
import { Raw as InputDuration } from '_shared/components/input/InputDuration'
import { Raw as InputSelect } from '_shared/components/input/InputSelect'

import SortListTable from '_shared/components/navigation/SortListTable'

import useDependencies from '_shared/hooks/useDependencies'
import useLocale from '_shared/hooks/useLocale'

import { route_segment } from './Context'

import {
  getDeepLocale,
} from '_shared/libs/nestedDataHelpers'

import {
  update,
} from '_shared/libs/mapToSchema'

import cloneDeep from 'lodash/cloneDeep'

import swap from '_shared/libs/arraySwap'

const FormRoute = ({
  current,
  updateSingle,
}) => {
  const {
    default_locale,
  } = useLocale()

  const { data: location_options } = useDependencies('locations', data => data
    .map(location => ({
      label: getDeepLocale(location.locales, 'title', default_locale),
      value: location.entity_id,
    })))

  return (
    <FormView>
      <Area
        areas={[
          'section',
          'selector/3 .',
        ]}
      >
        <SectionWrapper area={'section'}>
          <Heading level={1} title={'Define stops and travel time'} />
        </SectionWrapper>
        <Selector
          area={'selector'}
          field={'segments'}
          options={location_options}
          value={current.segments}
          change={updateSingle}
        />
      </Area>
    </FormView>
  )
}

const columns = [
  {
    key: 'stage',
    label: '',
  },
  {
    key: 'start',
    label: 'Travel',
  },
  {
    key: 'end',
    label: 'Duration',
  },
  {
    key: 'location',
    label: 'Stop',
  },
]

const fieldStyle = {
  flex: '0 0 auto',
  minHeight: '1.5rem',
}

const addTempID = (item, index) => ({
  ...item,
  temp_id: index + 1,
})

const removeTempID = ({ temp_id, ...rest }) => ({...rest})

const Selector = ({
  field: outer_field,
  value: collection,
  options,
  change,
}) => {
  const [
    internal,
    setInternal,
  ] = useState(collection.map(addTempID))

  const sync = (state) => {
    setInternal(state)

    change(outer_field, cloneDeep(state).map(removeTempID))
  }

  const create = () => {
    const state = [...internal]

    let row

    if (state.length > 0) {
      const start = state[0].end

      row = morphism(route_segment, { position: state.length, start })
    } else {
      row = morphism(route_segment, { position: state.length })
    }

    state.push({
      ...row,
      temp_id: state.length + 1,
    })

    sync(state)
  }

  const remove = (row) => {
    const state = [...internal].filter(
      el => el.position != row - 1,
    )
    sync(state)
  }

  const handleChange = (row) => (field, value) => {
    const state = [...internal]

    const length = state.length

    update(state, `[${row}]${field}`, value)

    if (row === 0 && field === 'end') {
      for (let x = 1; x < length; x++) {
        update(state, `[${x}]start`, value)
      }
    }

    sync(state)
  }

  const sort = (row, drag) => {
    const over = internal.findIndex(item => item.temp_id === row)
    const dragging = internal.findIndex(item => item.temp_id === drag)

    if (over === -1 || dragging === -1) return

    const mutated = swap(cloneDeep(internal), over, dragging)

    mutated.map((item, index) => {
      return {
        ...item,
        position: index,
      }
    })

    setInternal(mutated)
  }

  const finish = () => {
    const state = cloneDeep(internal)

    const end = state[0].end
    state[0].start = 'PT0M'

    for (let x = 1; x < state.length; x++) {
      update(state, `[${x}]start`, end)
    }

    setInternal(state)

    change(outer_field, state.map(removeTempID))
  }

  const getStage = (index) => {
    let output = index + 1
    if (index === 0) output = 'START'
    if (index === internal.length - 1) output = 'END'

    return (
      <InlineTextBox textAlign={'center'} width={'100%'} block>{output}</InlineTextBox>
    )
  }

  const getDurationField = (index, field, value, change) => {
    return (
      <InputDuration
        field={field}
        placeholder={'00h 00m'}
        value={value}
        change={change}
        {...fieldStyle}
        width={'6rem'}
        disabled={field === 'start'}
      />
    )
  }

  const mutatedData = internal.map(({
    temp_id,
    start,
    end,
    location,
  }, row) => {
    return {
      id: temp_id,
      stage: getStage(row),
      start: getDurationField(row, 'start', start, handleChange(row)),
      end: getDurationField(row, 'end', end, handleChange(row)),
      location: (
        <InputSelect
          field={'location.entity_id'}
          placeholder={'Choose a location'}
          options={options}
          value={location.entity_id}
          change={handleChange(row)}
          {...fieldStyle}
        />
      ),
    }
  })

  return (
    <SortListTable
      columns={columns}
      data={mutatedData}
      create={create}
      remove={remove}
      sort={sort}
      finish={finish}
    />
  )
}

export default FormRoute
