import React from 'react'

import Area from '_shared/components/layout/Area'

import FormView from '_shared/components/layout/FormView'

import Heading from '_shared/components/layout/Heading'
import TextBox from '_shared/components/layout/TextBox'
import InputText, { Localised as InputTextLocalised } from '_shared/components/input/InputText'
import InputSelect from '_shared/components/input/InputSelect'

import InfoBox from '_shared/components/notification/InfoBox'

import {
  parseValidity,
} from '_shared/libs/validityChecks'

import Config from 'libs/Config'
import upperCaseFirst from 'libs/upperCaseFirst'

const FormDescription = ({
  current,
  valid,
  updateSingle,
}) => {
  const icon_options = Object.keys(Config.icons)
    .map(icon => ({
      label: upperCaseFirst(icon),
      value: icon,
    }))

  return (
    <FormView>
      <Area
        areas={[
          'section',
          'info/2 code_info',
          'code . code_info',
          'title . warning',
          'description/2 warning',
          'icon . .',
        ]}
        rowgap={1.5}
      >
        <Heading level={1} title={'Description'} area={'section'} />
        <TextBox strong area={'info'}>{'Ticket groups are used for customer purchase searches and managing what can access a vehicle or venue.'}</TextBox>
        <InfoBox
          area={'code_info'}
          type={'info'}
          title={'External ID'}
          content={'Used for external API calls.'}
        />
        <InfoBox
          area={'warning'}
          type={'warning'}
          title={'Warning'}
          content={'Changing details here will change it throughout the services'}
        />
        <InputText
          area={'code'}
          title={'External ID'}
          field={'external_id'}
          value={current.external_id}
          placeholder={'Unique External ID'}
          change={updateSingle}
          status={parseValidity(valid.external_id)}
          required
          requiredText={'Must be unique'}
        />
        <InputTextLocalised
          area={'title'}
          title={'Name - customer facing'}
          field={'title'}
          value={current.locales}
          placeholder={'Group name'}
          change={updateSingle}
          valid={valid.locales}
          required
        />
        <InputTextLocalised
          area={'description'}
          title={'Description'}
          field={'description'}
          value={current.locales}
          placeholder={'Group description'}
          change={updateSingle}
        />
        <InputSelect
          area={'icon'}
          title={'Display Icon'}
          field={'icon'}
          value={current.icon}
          placeholder={'Choose an icon'}
          options={icon_options}
          change={updateSingle}
        />
      </Area>
    </FormView>
  )
}

export default FormDescription
