import React from 'react'
import {
  connect,
} from 'react-redux'

import {
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'

import routes from 'config/routes'

import { readShortcuts } from 'libs/shortcuts'

const Routes = (props) => {
  const config = [].concat(...routes)

  const parsedRoutes = []

  config.forEach(route => {
    if (route.capability && (!props.permissions[route.capability] || (props.permissions[route.capability] && !props.permissions[route.capability].includes('read')))) return

    if (route.type === 'nav') {
      parsedRoutes.push(route)
    } else {
      route.subRoutes.forEach(subRoute => {
        parsedRoutes.push({
          ...subRoute,
          path: `${route.path}${subRoute.path}`,
        })
      })
    }
  })

  const hasShortcuts = readShortcuts()

  return (
    <Switch>
      {parsedRoutes.map((route, index) => {
        return (
          <Route
            key={index}
            path={route.path}
            component={route.component}
          />
        )
      })}
      <Redirect from={'/'} to={hasShortcuts ? '/home' : '/blocks/tickets'} />
    </Switch>
  )
}

const mapStateToProps = (state) => {
  const {
    user,
  } = state.user

  return {
    permissions: user.permissions,
  }
}

export default connect(mapStateToProps)(Routes)
