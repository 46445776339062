import React, { Fragment } from 'react'

import Column from '_shared/components/layout/Column'
import Row from '_shared/components/layout/Row'
import InlineTextBox from '_shared/components/layout/InlineTextBox'

import Tick from '_shared/components/element/Tick'
import Button from '_shared/components/element/Button'

// import StyleWrapper from '_shared/style/StyleWrapper'

const InputRadio = ({
  field,
  value,
  change,
  options,
  type = 'vertical',
  disabled = false,
  rounded = true,
  ...style
}) => {
  const handleChange = (value) => change(field, value)

  const Wrapper = type === 'vertical' ? Column : Row

  return (
    <Wrapper crossAxis={type === 'vertical' ? 'flex-start' : 'center'} {...style}>
      <Radios
        options={options}
        value={value}
        change={handleChange}
        disabled={disabled}
        rounded={rounded}
      />
    </Wrapper>
  )
}

const Radios = ({
  value,
  options,
  change,
  disabled,
  rounded,
}) => {
  return (
    <Fragment>
      {options.map(option => {
        return (
          <Button rawStyle={{ justifyContent: 'flex-start' }} change={() => change(option.value)} key={option.value} disabled={disabled}>
            <Tick active={Boolean(value === option.value)} disabled={disabled} rounded={rounded} />
            <InlineTextBox margin={'0 0 0 0.5rem'}>{option.label}</InlineTextBox>
          </Button>
        )
      })}
    </Fragment>
  )
}

export default InputRadio
