import React, { useEffect, useCallback } from 'react'

import useActions from '_shared/hooks/useActions'
import useForm from '_shared/hooks/useForm'
import useStatus from '_shared/hooks/useStatus'

import SubSlide, { Controls } from '_shared/components/layout/SubSlide'

const SubSlideForm = ({
  data_spec,
  valid_spec,
  handlers,
  mount,
  permissionsKey,
  render,
  record = null,
  initial = {},
  close,
  handleSave,
}) => {
  const {
    current,
    valid,
    allValid,
    reset,
    update,
  } = useForm(data_spec, valid_spec, initial, {})

  const [
    state,
    dispatch,
  ] = useStatus()

  const {
    permissions,
    load,
    save,
    cancel,
  } = useActions(handlers, permissionsKey, state.status, dispatch, current)

  const wrappedLoad = useCallback(async (...parameters) => {
    const record = await load(...parameters)

    if (parameters.length > 0) {
      reset(record)
    } else {
      reset(initial)
    }
  }, [initial, load, reset])

  useEffect(() => {
    if (mount) {
      record ? wrappedLoad(record) : wrappedLoad()
    }
  }, [mount])

  const updateSingle = (field, value) => update([{ field, value }])

  const wrappedCancel = () => {
    cancel()
    close()
    reset(initial)
  }

  const wrappedSave = async () => {
    await save(current)
    close()
    reset(initial)
    handleSave && handleSave()
  }

  return (
    <SubSlide
      mount={mount}
      controls={() => {
        return (
          <Controls
            saveAction={wrappedSave}
            cancelAction={wrappedCancel}
            permissions={permissions}
            disabled={!allValid}
          />
        )
      }}
    >
      {render({
        current,
        valid,
        update,
        updateSingle,
      })}
    </SubSlide>
  )
}

export default SubSlideForm
