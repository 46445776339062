import React from 'react'

import Column from '_shared/components/layout/Column'
import Container from '_shared/components/layout/Container'
import Row from '_shared/components/layout/Row'
import TextBox from '_shared/components/layout/TextBox'

import Button from '_shared/components/element/Button'

import LeftArrow from 'icons/Left'
import RightArrow from 'icons/Right'

import colors from 'config/colors'

import CarouselWrapper from '_shared/components/layout/CarouselWrapper'

const Action = ({
  action,
  children,
  active,
  ...style
}) => {

  return (
    <Column
      type={'center'}
      noFlex
      width={'2.25rem'}
      height={'2.25rem'}
      background={'text'}
      rawStyle={{ opacity: active ? 1 : 0.3 }}
      {...style}
    >
      <Button change={action}>
        {children}
      </Button>
    </Column>
  )
}

const Carousel = ({
  advance,
  leftActive,
  rightActive,
  cards,
  current,
  count,
}) => {
  const Prev = (
    <Action
      action={advance.bind(this, 'left')}
      active={leftActive}
    >
      <LeftArrow
        size={12}
        fill={colors.color_ui}
      />
    </Action>
  )

  const Next = (
    <Action
      action={advance.bind(this, 'right')}
      active={rightActive}
    >
      <RightArrow
        size={12}
        fill={colors.color_ui}
      />
    </Action>
  )

  const showArrows = count > 1

  return (
    <Container>
      <Container rawStyle={{ clipPath: 'inset(0 0 -100% 0)' }}>
        {cards}
      </Container>
      <Row type={'center'} padding={'1rem 0'} spread={false}>
        {showArrows && Prev}
        <TextBox margin={'0 2rem'}>
          {`${current + 1} of ${count}`}
        </TextBox>
        {showArrows && Next}
      </Row>
    </Container>
  )
}

const Loader = (props) => {
  return (
    <CarouselWrapper
      {...props}
      render={({...controls}) => {
        return (
          <Carousel
            {...controls}
          />
        )
      }}
    />
  )
}

export default Loader
