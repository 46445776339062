import React, { Fragment } from 'react'

import View from '_shared/components/layout/View'
import Heading from '_shared/components/layout/Heading'

import Reports from './shortcuts/Reports'

import { readShortcuts } from 'libs/shortcuts'

const options = {
  reports: Reports,
}

const Home = () => {
  const hasShortcuts = readShortcuts()

  return (
    <View>
      <Heading
        level={'section'}
        title={'Home'}
      />
      {hasShortcuts && (
        <Shortcuts />
      )}
    </View>
  )
}

const Shortcuts = () => {
  const {
    shortcuts,
  } = window.TICKNOVATE_CONFIG.app

  const shortcutDisplay = Object.keys(shortcuts)
    .filter(key => shortcuts[key].length > 0)
    .map(key => key)

  return (
    <Fragment>
      {shortcutDisplay.map(type => {
        const Module = options[type]

        return (
          <Module value={shortcuts[type]} />
        )
      })}
    </Fragment>
  )
}

export default Home
