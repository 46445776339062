import { createSchema } from 'morphism'
import api from 'api/login'

import { mapDefaults } from '_shared/libs/mapToSchema'

import {
  notEmptyOrNull,
} from '_shared/libs/validityChecks'

const defaults = {
  department: '',
  email: '',
  enabled: true,
  name: '',
  roles: '',
  status: '',
  username: '',
}

const getSpec = () => {
  return createSchema(
    {
      department: 'department',
      email: 'email',
      enabled: 'enabled',
      name: 'name',
      roles: 'roles',
      status: 'status',
      username: 'username',
    },
    mapDefaults(defaults),
  )
}

const valid = {
  email: notEmptyOrNull,
  name: notEmptyOrNull,
  roles: (record, value) => {
    const actions = value.split(',')

    let valid = false

    if (actions.length > 0) {
      valid = actions
        .map(action => action.split(':')[0])
        .some(app => app === 'admin' || app === 'reservations' || app === 'pos')
    }

    return valid
  },
}

const handlers = {
  store: 'profiles',
  get: api.getUserProfile,
  new: api.createProfile,
  edit: (payload) => api.editProfile(payload.username, payload),
  remove: () => {},
  cancel: null,
}

export {
  valid,
  handlers,
  getSpec,
}
