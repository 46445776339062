import { createSchema, morphism } from 'morphism'
import api from 'api/product'

import {
  mapLocales,
  mapDefaults,
  mapToArraySubSchema,
} from '_shared/libs/mapToSchema'

import {
  isUnique,
  checkValidID,
} from '_shared/libs/validityChecks'

import uniq from 'lodash/uniq'

const defaults = {
  'type': '',
  'external_id': '',
  'ticket_exchange_required': false,
  'service_instance_conditions.day_of_week': [],
  'booking_unit': {
    type: 'open',
  },
  'direction': 'fixed',
  'doors_open_offset': 'PT10M',
  'sales_limitations.date.start_date': new Date().toISOString().split('T')[0],
  'sales_limitations.date.end_date': new Date().toISOString().split('T')[0],
  'multi_usage_rules.max_uses': 1,
  'multi_usage_rules.active_period.type': 'period',
  'multi_usage_rules.active_period.period': 'PT24H',
  'pricing': [],
  'pricing_rules': [],
  'status': 'active',
  'extensions': {},
  'booking_fee_enabled': false,
  'booking_fee_type': 'per_booking',
  'booking_fee_refundable': false,
  'booking_fee_values': [],
  'deposit_values': [],
  'deposit_enabled': false,
  'deposit_type': 'per_booking',
  'minimum_spend_enabled': false,
  'minimum_spend_values': [],
  'minimum_spend_type': 'per_booking',
  'qty_limit_per_booking_min': null,
  'qty_limit_per_booking_max': null,
  'qty_limit_per_booking_type': 'none',
}


const getServiceSchema = () => {
  return createSchema(
    {
      entity_id: 'entity_id',
    },
    mapDefaults(defaults),
  )
}

const getEntitySchema = () => {
  return createSchema(
    {
      entity_id: 'entity_id',
    },
  )
}

const getPricingTicketSchema = () => {
  return createSchema(
    {
      channel: 'channel',
      type: 'type',
      value: 'value',
      market_id: 'market_id',
      ticket_type_id: 'ticket_type_id',
      location_id: 'location_id',
      end_location_id: 'end_location_id',
      target: 'target',
      all_ticket_type: 'all_ticket_type',
    },
    mapDefaults({
      'type': 'set_fixed',
      'all_ticket_types': false,
      'target': 'price',
      'value': 0,
    }),
  )
}

const getVatRateSchema = () => {
  return createSchema(
    {
      market: {
        entity_id: 'market.entity_id',
      },
      amount: 'amount',
    },
    mapDefaults({
      'amount': 0,
    }),
  )
}

const getSalesEndSchema = () => {
  return createSchema({
    channel: 'channel',
    start_offset: 'start_offset',
  }, mapDefaults({
    channel: 0,
    start_offset: 'PTOM',
  }))
}

const getRouteSchema = (locales) => {
  return createSchema(
    {
      departure_location: {
        entity_id: 'departure_location.entity_id',
      },
      arrival_location: {
        entity_id: 'arrival_location.entity_id',
      },
      legs: {
        path: 'legs',
        fn: mapToArraySubSchema(getLegSchema, locales),
      },
    },
  )
}

const getLegSchema = (locales) => {
  return createSchema(
    {
      departure_location: {
        entity_id: 'departure_location.entity_id',
      },
      arrival_location: {
        entity_id: 'arrival_location.entity_id',
      },
      locales: {
        path: 'locales',
        fn: (value) => mapLocales({
          important_info: '',
        }, value, locales),
      },
    },
  )
}

const getSchema = (locales, currencies) => {
  return createSchema(
    {
      entity_id: 'entity_id',
      external_id: 'external_id',
      status: 'status',
      type: 'type',
      booking_fee_enabled: 'booking_fee_enabled',
      booking_fee_refundable: 'booking_fee_refundable',
      booking_fee_type: 'booking_fee_type',
      booking_fee_values: 'booking_fee_values',
      deposit_enabled: 'deposit_enabled',
      deposit_type: 'deposit_type',
      deposit_values: 'deposit_values',
      minimum_spend_enabled: 'minimum_spend_enabled',
      minimum_spend_values: 'minimum_spend_values',
      minimum_spend_type: 'minimum_spend_type',
      service_instance_conditions: {
        after_offset_from_now: 'service_instance_conditions.after_offset_from_now',
        before_offset_from_now: 'service_instance_conditions.before_offset_from_now',
        start_time: 'service_instance_conditions.start_time',
        end_time: 'service_instance_conditions.end_time',
        start_date: 'service_instance_conditions.start_date',
        end_date: 'service_instance_conditions.end_date',
        day_of_week: {
          path: 'service_instance_conditions.day_of_week',
          fn: (value) => {
            if (!value) return []
            return value
          },
        },
      },
      services: {
        path: 'services',
        fn: mapToArraySubSchema(getServiceSchema, locales),
      },
      assets: 'assets',
      booking_unit: {
        path: 'booking_unit',
        fn: (value) => {
          if (!value) {
            return {
              type: 'time',
              blocks: [],
              first_use_within: {
                period: 'PT24H',
                type: 'period',
              },
            }
          }

          if (value.type === 'open' && !value.blocks) {
            return {
              type: 'open',
              blocks: [],
              first_use_within: {
                period: 'PT24H',
                type: 'period',
              },
            }
          }

          if (value.type === 'dated_time_range') {
            value.type = 'time'
          }

          return morphism(
            createSchema(
              {
                type: 'type',
                blocks: 'blocks',
                first_use_within: {
                  period: 'first_use_within.period',
                  type: 'first_use_within.type',
                },
              },
              mapDefaults({
                'type': 'time',
                'blocks': 'blocks',
                'first_use_within.period': 'PT24H',
                'first_use_within.type': 'period',
              }),
            ), value)
        },
      },
      direction: 'direction',
      doors_open_offset: 'doors_open_offset',
      extensions: 'extensions',
      sales_limitations: {
        channels: {
          path: 'sales_limitations.channels',
          fn: (value) => {
            if (!value) return []
            return value
          },
        },
        markets: {
          path: 'sales_limitations.markets',
          fn: mapToArraySubSchema(getEntitySchema, locales),
        },
        date: {
          start_date: 'sales_limitations.date.start_date',
          end_date: 'sales_limitations.date.end_date',
        },
        sales_end_date: {
          path: 'sales_limitations.sales_end_date',
          fn: mapToArraySubSchema(getSalesEndSchema, locales),
        },
        show_sales_end: (root) => {
          if (!root.sales_limitations?.sales_end_date || root.sales_limitations.sales_end_date.length === 0) return false
          return true
        },
      },
      locales: {
        path: 'locales',
        fn: (value) => mapLocales({
          title: '',
          short_title: '',
          description: '',
          important_information: '',
          whats_onboard: '',
          ticket_redemption_instructions: '',
          short_description: '',
          category: '',
        }, value, locales),
      },
      multi_usage_rules: {
        max_uses: 'multi_usage_rules.max_uses',
        active_period: {
          type: 'multi_usage_rules.active_period.type',
          period: 'multi_usage_rules.active_period.period',
        },
      },
      pricing: {
        path: 'pricing',
        fn: mapToArraySubSchema(getPricingTicketSchema, locales, currencies),
      },
      pricing_rules: 'pricing_rules',
      vat_rate: {
        path: 'vat_rate',
        fn: mapToArraySubSchema(getVatRateSchema, locales, currencies),
      },
      terms: {
        entity_id: 'terms.entity_id',
      },
      ticket_exchange_required: 'ticket_exchange_required',
      routes: {
        path: 'routes',
        fn: mapToArraySubSchema(getRouteSchema, locales),
      },
      allowed_ticket_types: {
        path: 'pricing',
        fn: (effects) => {
          if (!effects) return []
          return uniq(effects.map(effect => effect.ticket_type_id))
            .map(ticket_type_id => ({
              ticket_type: {
                entity_id: ticket_type_id,
              },
            }))
        },
      },
      qty_limit_per_booking_min: 'qty_limit_per_booking_min',
      qty_limit_per_booking_max: 'qty_limit_per_booking_max',
      qty_limit_per_booking_type: 'qty_limit_per_booking_type',
    },
    mapDefaults(defaults),
  )
}

const valid = {
  external_id: (record, value, unique_map) => {
    return isUnique('external_id')(record, value, unique_map) && checkValidID(value) && value !== ''
  },
  locales: (record, value) => {
    return value
      .map(locale => locale.title)
      .some(locale => locale !== '')
  },
  services: (record, value) => {
    return value.every(service => service.entity_id !== null)
  },
  terms: (record, value) => {
    return value.entity_id !== null
  },
}

// If there is a price for a ticket, it is enabled to sell. Filter out prices for disabled ticket types
const filterPricing = (allowed_ticket_types, effects) => {
  const ticket_filter = allowed_ticket_types.map(type => type.ticket_type.entity_id)

  return effects.filter(effect => (
    effect.market_id != null &&
    effect.value != null &&
    ticket_filter.includes(effect.ticket_type_id)
  ))
}

const filterSalesEnd = (sales_limitations) => {
  const { sales_end_date, show_sales_end } = sales_limitations
  return show_sales_end ? sales_end_date : []
}

const filterFeeValues = (fees, enabled) => {
  if (!enabled) return []

  return fees.filter(({ value }) => value != null)
}

const handlers = {
  store: 'products',
  unique_key: 'external_id',
  get: api.getProduct,
  new: (payload) => {
    const {
      allowed_ticket_types,
      pricing,
      sales_limitations,
      booking_fee_enabled,
      booking_fee_refundable,
      booking_fee_values,
      deposit_enabled,
      deposit_values,
      minimum_spend_enabled,
      minimum_spend_values,
      ...rest
    } = payload

    return api.createProduct({
      ...rest,
      pricing: filterPricing(allowed_ticket_types, pricing),
      sales_limitations: {
        ...sales_limitations,
        sales_end_date: filterSalesEnd(sales_limitations),
      },
      booking_fee_refundable: !booking_fee_enabled ? false : booking_fee_refundable,
      booking_fee_enabled,
      booking_fee_values: filterFeeValues(booking_fee_values, booking_fee_enabled),
      deposit_enabled,
      deposit_values: filterFeeValues(deposit_values, deposit_enabled),
      minimum_spend_enabled,
      minimum_spend_values: filterFeeValues(minimum_spend_values, minimum_spend_enabled),
    })
  },
  edit: (payload) => {
    const {
      entity_id,
      allowed_ticket_types,
      pricing,
      sales_limitations,
      booking_fee_enabled,
      booking_fee_refundable,
      booking_fee_values,
      deposit_enabled,
      deposit_values,
      minimum_spend_enabled,
      minimum_spend_values,
      ...rest
    } = payload

    return api.updateProduct(
      entity_id,
      {
        ...rest,
        entity_id,
        pricing: filterPricing(allowed_ticket_types, pricing),
        sales_limitations: {
          ...sales_limitations,
          sales_end_date: filterSalesEnd(sales_limitations),
        },
        booking_fee_refundable: !booking_fee_enabled ? false : booking_fee_refundable,
        booking_fee_enabled,
        booking_fee_values: filterFeeValues(booking_fee_values, booking_fee_enabled),
        deposit_enabled,
        deposit_values: filterFeeValues(deposit_values, deposit_enabled),
        minimum_spend_enabled,
        minimum_spend_values: filterFeeValues(minimum_spend_values, minimum_spend_enabled),
      },
    )
  },
  remove: () => { },
  cancel: null,
}

export {
  getSchema,
  getEntitySchema,
  getServiceSchema,
  getPricingTicketSchema,
  getLegSchema,
  valid,
  handlers,
}
