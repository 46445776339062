import Config from 'libs/Config'

const convert = (options) => {
  const colorExists = (key) => Config.theme.hasOwnProperty(key) ? Config.theme[key] : Config.theme.text

  const styleValues = {
    'color': (value) => ({ 'color': colorExists(value) }),
    'size': (value) => ({ 'fontSize': `${value}rem`}),
    'background': (value) => ({ 'backgroundColor': colorExists(value) }),
    'strong': () => ({ 'fontWeight': 'bold' }),
    'em': () => ({ 'fontStyle': 'italic' }),
    'block': () => ({ 'display': 'block' }),
    'margin': (value) => ({ 'margin': value }),
    'padding': (value) => ({ 'padding': value }),
    'width': (value) => ({ 'width': value }),
    'minWidth': (value) => ({ 'minWidth': value }),
    'height': (value) => ({ 'height': value }),
    'noMargin': (value) => ({ 'margin': 0 }),
    'minHeight': (value) => ({ 'minHeight': value }),
    'maxHeight': (value) => ({ 'maxHeight': value }),
    'crossAxis': (value) => ({ 'alignItems': value }),
    'mainAxis': (value) => ({ 'justifyContent': value }),
    'flex': (value) => ({ 'flex': value }),
    'flexCenter': () => ({ 'display': 'flex', 'flexDirection': 'row', 'alignItems': 'center', 'justifyContent': 'center' }),
    'noFlex': () => ({ flexGrow: 0, flexShrink: 0 }),
    'fullFlex': () => ({ flexGrow: 1, flexShrink: 1 }),
    'flexWrap': () => ({ flexWrap: 'wrap' }),
    'absRight': (value) => ({ 'position': 'absolute', 'right': value }),
    'cover': () => ({ 'position': 'absolute', 'left': 0, 'right': 0, 'top': 0, 'bottom': 0 }),
    'area': (value) => ({ gridArea: value }),
    'gridAlign': (value) => ({ alignSelf: value }),
    'textAlign': (value) => ({ textAlign: value }),
    'uppercase': () => ({ textTransform: 'uppercase' }),
    'textTransform': (value) => ({ textTransform: value }),
    'truncate': () => ({ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }),
    'buttonLabel': () => ({ fontSize: '1.25rem', 'fontWeight': 'bold' }),
    'pushRight': () => ({ marginRight: 'auto' }),
    'pushLeft': () => ({ marginLeft: 'auto' }),
    'shadow': () => ({ boxShadow: '0 1px 2px rgba(0, 0, 0, 0.3)' }),
    'overflow': (value) => ({ overflow: value }),
    'borderRadius': (value) => ({ borderRadius: value }),
    'rawStyle': (value) => value, // Escape hatch for a complete override
  }

  const styling = {}

  for (let prop in styleValues) {
    if (typeof options[prop] !== 'undefined' && options[prop] !== null && options[prop] !== false) {
      Object.assign(styling, styleValues[prop](options[prop]))
    }
  }

  return styling
}

export default convert
