import React from 'react'

import styles from './alert.scss'

import Row from '_shared/components/layout/Row'
import TextBox from '_shared/components/layout/TextBox'
import Button from '_shared/components/element/Button'

// import delayMount from 'HOC/delayMount'

import TickIcon from '_shared/icons/Tick'
import CancelIcon from '_shared/icons/Cancel'
import WarningIcon from '_shared/icons/Warning'

import useDelayedRender from '_shared/hooks/useDelayedRender'

import Config from 'libs/Config'

const states = {
  new: {
    Icon: TickIcon,
    background: 'button_success',
  },
  edit: {
    Icon: TickIcon,
    background: 'button_success',
  },
  warning: {
    Icon: WarningIcon,
    background: 'button_warning',
  },
  error: {
    Icon: WarningIcon,
    background: 'button_stop',
  },
}

const message = {
  fontSize: '1.2rem',
  marginRight: 'auto',
  whiteSspace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
}

const Alert = ({
  id,
  data,
  pause,
  dismiss,
  isMounted,
  position,
}) => {
  const shouldRender = useDelayedRender({mounted: isMounted})

  const styling = [styles.layout]

  styling.push(isMounted ? null : styles.out)

  const style = {
    transform: `translateY(-${position * 4}rem)`,
  }

  const type = states[data.type] ? data.type : 'new'

  const {
    Icon,
    background,
  } = states[type]

  if (!shouldRender) return null

  return (
    <div
      className={styling.join(' ')}
      style={isMounted ? style : null}
      onMouseOver={() => pause(true)}
      onMouseOut={() => pause(false)}
    >
      <Button change={() => dismiss(id)} width={'100%'} padding={'0 1rem'} background={background}>
        <Row type={'start'}
          height={'3rem'}
        >
          <Icon size={20} fill={Config.theme.text_light} />
          <TextBox
            color={'text_light'}
            strong
            rawStyle={message}
          >
            {data.message}
          </TextBox>
          <CancelIcon
            size={20}
            fill={Config.theme.text_light}
          />
        </Row>
      </Button>
    </div>
  )
}

export default Alert
