import React from 'react'

import styles from './historyslide.scss'

import styleCombine from 'libs/styleCombine'

const SlideContent = (props) => {
  const {
    isMounted,
    onAnimationEnd,
    children,
  } = props

  return (
    <div
      className={styleCombine(styles.caddy, !isMounted && styles.out)}
      onAnimationEnd={onAnimationEnd}
    >
      <div className={styles.content}>
        {children}
      </div>
    </div>
  )
}

export default SlideContent
