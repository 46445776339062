import React from 'react'

const OnlyOneIcon = (props) => {
  const {
    size,
    fill,
  } = props

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
    >
      <path d='M0 0h24v24H0z' fill='none' />
      <path d='M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM11.8698 7.75521L12.7031 7.75521L12.7031 16.1406L11.5833 16.1406L11.5833 10.1771L9.60417 10.1771L9.60417 9.34375C10.3692 9.26921 10.8906 9.15375 11.1927 8.97917C11.4948 8.80458 11.7207 8.40646 11.8698 7.75521Z' fill={fill} />
    </svg>
  )
}

export default OnlyOneIcon