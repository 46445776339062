import React, { useState, useEffect } from 'react'

import { useDispatch } from 'react-redux'

import { useParams } from 'react-router-dom'

import styles from './historyfull.scss'

import ReactDOM from 'react-dom'

import SlideContent from './Content'

import Controls from './Controls'

// const defaultCapability = [
//   'read',
//   'create',
//   'update',
//   'delete'
// ]

const matchKeys = (match, params) => {
  return Object.keys(match)
    .reduce((acc, cur) => {
      return (params[cur] && params[cur] === match[cur])
    }, false)
}

const HistorySlide = ({
  children,
  permissions = [],
  mountKey,
  match,
  controls,
}) => {
  const reduxDispatch = useDispatch()

  const params = useParams()

  const matched = match ? matchKeys(match, params) : params[mountKey]

  const [
    shouldRender,
    setRender,
  ] = useState(matched)

  useEffect(() => {
    if (matched) {
      setRender(true)
      reduxDispatch({
        type: 'UI_SET_COLLAPSED',
        collapsed: true,
      })
    }
  }, [matched, reduxDispatch])

  if (!document.getElementById('full')) return null

  const onAnimationEnd = () => {
    if (!matched) {
      setRender(false)
      reduxDispatch({
        type: 'UI_SET_COLLAPSED',
        collapsed: false,
      })
    }
  }

  return ReactDOM.createPortal(
    (
      shouldRender &&
      <div className={styles.layout}>
        <SlideContent
          permissions={permissions}
          isMounted={matched}
          onAnimationEnd={onAnimationEnd}
        >
          <div className={styles.scroller}>
            {children}
          </div>
          {controls && controls()}
        </SlideContent>
      </div>
    ),
    document.getElementById('full'),
  )
}

export default HistorySlide

export {
  Controls,
}
