import React, { Component, useState } from 'react'
import {
  connect,
} from 'react-redux'

import {
  reAuth,
  logout,
} from 'actions/user'

import styles from './request.scss'

import Row from '_shared/components/layout/Row'

import Heading from '_shared/components/layout/Heading'
import TextBox from '_shared/components/layout/TextBox'

import { Raw as InputText } from '_shared/components/input/InputText'
import { Raw as InputPassword } from '_shared/components/input/InputPassword'

import ActionCTA from '_shared/components/action/ActionCTA'

const RequestAuth = (props) => {
  const [current, setRecord] = useState({
    username: '',
    password: '',
  })

  const handleUpdate = (field, value) => {
    const state = { ...current }

    state[field] = value

    setRecord(state)
  }

  const handleSubmit = () => {
    props.reAuth({ ...current })
  }

  const handleLogout = () => {
    props.logout()
  }

  const catchSubmit = (event) => {
    event.preventDefault()
  }

  const formValid = current.username !== '' && current.password !== ''

  return (
    <div className={styles.layout}>
      <form onSubmit={catchSubmit}>
        <div className={styles.content}>
          <Heading
            level={1}
            title={'Your current session has expired'}
          />
          <TextBox>{'Please login again so that we can re-authorise you.'}</TextBox>
          <Row type={'start'}>
            <InputText
              field={'username'}
              placeholder={'Email'}
              value={current.username}
              change={handleUpdate}
              status={current.username !== '' ? 'VALID' : 'INVALID'}
              required
              requiredText={'User email required'}
              width={'15rem'}
              noFlex
            />
            <InputPassword
              field={'password'}
              placeholder={'Password'}
              value={current.password}
              change={handleUpdate}
              status={current.password !== '' ? 'VALID' : 'INVALID'}
              required
              requiredText={'Password required'}
              width={'15rem'}
              noFlex
            />
          </Row>
          <Row type={'spaced'} margin={'1.5rem 0 0 0'}>
            <ActionCTA
              type={formValid ? 'important' : 'disabled'}
              label={'Login'}
              change={formValid  ? handleSubmit : null}
            />
            <ActionCTA
              type={'unimportant'}
              label={'Logout'}
              change={handleLogout}
            />
          </Row>
        </div>
      </form>
    </div>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    reAuth: (data) => dispatch(reAuth(data)),
    logout: () => dispatch(logout()),
  }
}

export default connect(null, mapDispatchToProps)(RequestAuth)
