import { useRef, useState, useLayoutEffect } from 'react'

const useDelayedRender = ({
  mounted,
  duration = 500,
}) => {
  const mountState = useRef(mounted)

  const [ shouldRender, setShouldRender ] = useState(mounted)

  useLayoutEffect(() => {
    if (mountState.current && !mounted) {
      setTimeout(
        () => setShouldRender(false),
        duration,
      )
    } else if (!mountState.current && mounted) {
      setShouldRender(true)
    }
  }, [mounted])

  return shouldRender
}

export default useDelayedRender
