import React from 'react'

import styles from './floatingactions.scss'

import ActionCTA from '_shared/components/action/ActionCTA'

import useDelayedRender from '_shared/hooks/useDelayedRender'

const FloatingActions = ({
  discard,
  save,
  isMounted,
}) => {
  const shouldRender = useDelayedRender({mounted: isMounted})

  if (!shouldRender) return null

  return (
    <div className={[styles.layout, isMounted ? styles.in : styles.out].join(' ')}>
      <ActionCTA
        label={'Discard Changes'}
        type={'unimportant'}
        change={discard}
      />
      <ActionCTA
        label={'Save Changes'}
        type={'important'}
        change={save}
      />
    </div>
  )
}

export default FloatingActions
