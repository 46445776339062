import React, { useState, useEffect } from 'react'

import styles from './subslide.scss'

import ReactDOM from 'react-dom'

import SlideContent from './Content'

import Controls from './Controls'

// const defaultCapability = [
//   'read',
//   'create',
//   'update',
//   'delete'
// ]

const SubSlide = ({
  children,
  permissions = [],
  mount = false,
  controls,
}) => {
  const [
    shouldRender,
    setRender,
  ] = useState(mount)

  useEffect(() => {
    if (mount) setRender(true)
  }, [mount])

  if (!document.getElementById('slide')) return null

  const onAnimationEnd = () => {
    if (!mount) setRender(false)
  }

  return ReactDOM.createPortal(
    (
      shouldRender &&
      <div className={styles.layout}>
        <SlideContent
          permissions={permissions}
          isMounted={mount}
          onAnimationEnd={onAnimationEnd}
        >
          <div className={styles.scroller}>
            {children}
          </div>
          {controls && controls()}
        </SlideContent>
      </div>
    ),
    document.getElementById('slide'),
  )
}

export default SubSlide

export {
  Controls,
}
