import { createSchema, morphism } from 'morphism'
import api from 'api/product'

import { mapLocales, mapDefaults } from '_shared/libs/mapToSchema'


import { getAreaSpec } from '../../BuildingBlocks/subviews/Layouts/form/Context'

import {
  notEmptyOrNull,
} from '_shared/libs/validityChecks'

const defaults = {
  title: '',
  description: '',
  duration: '',
  segments: [],
  type: '',
  notes: [],
  single_booking_only: false,
}

const ticket_type_group = {
  entity_id: 'entity_id',
}

const route_segment = createSchema(
  {
    position: 'position',
    start: 'start',
    end: 'end',
    location: {
      entity_id: 'location.entity_id',
    },
  },
  mapDefaults({
    position: 0,
    start: 'PT0M',
    end: 'PT0M',
  }),
)

const getSpec = (locales) => {
  return createSchema(
    {
      entity_id: 'entity_id',
      title: 'title',
      description: 'description',
      type: 'type',
      locales: {
        path: 'locales',
        fn: (value, source) => mapLocales({
          boarding_time: '',
          whats_onboard: '',
        }, value, locales),
      },
      compatible_ticket_type_groups: {
        path: 'compatible_ticket_type_groups',
        fn: (value, source) => {
          if (!value) return []
          return value.map(record => morphism(ticket_type_group, record))
        },
      },
      venue: {
        entity_id: 'venue.entity_id',
      },
      service: {
        entity_id: 'service.entity_id',
      },
      notes: 'notes',
      layout: {
        areas: {
          path: 'layout.areas',
          fn: (value, source) => {
            if (!value) return []
            return value.map(record => morphism(getAreaSpec(locales), record))
          },
        },
      },
      segments: {
        path: 'segments',
        fn: (value, source) => {
          if (!value) return []
          return value.map(record => morphism(route_segment, record))
        },
      },
      single_booking_only: 'single_booking_only',
    },
    mapDefaults(defaults),
  )
}

const valid = {
  title: notEmptyOrNull,
  venue: (record, value) => {
    return value !== null
  },
  compatible_ticket_type_groups: (record, value) => {
    return value.length > 0
  },
  layout: (record, value) => {
    return value.areas && value.areas.length > 0
  },
  segments: (record, value) => {
    const empty = value.every((leg) => leg.end === 'P0D')
    if (empty) return false
    return value.length > 0 && value
      .every(({ location }) => location.entity_id !== null)
  },
}

const apiValid = {
  title: notEmptyOrNull,
  segments: (record, value) => {
    return value.length > 0 && value
      .every(({ location }) => location.entity_id !== null)
  },
}

const handlers = {
  store: 'instance_templates',
  get: api.getInstanceTemplate,
  new: api.createInstanceTemplate,
  edit: (payload) => api.updateInstanceTemplate(payload.entity_id, payload),
  remove: (payload) => { },
  cancel: null,
}

export {
  getSpec,
  valid,
  apiValid,
  handlers,
  route_segment,
}