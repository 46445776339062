import React, { Fragment } from 'react'
import {
  connect,
} from 'react-redux'

import NavIcon from './NavIcon'

import View from '_shared/components/layout/View'

import Heading from '_shared/components/layout/Heading'

import { TabbedGroup } from '_shared/components/navigation/TabbedHeader'
import ShadowWrapper from '_shared/components/layout/ShadowWrapper'

import useNavigationView from '_shared/hooks/useNavigationView'

import Consumer from './form/Consumer'

import UserAll from './form/UserAll'
import UserAdmin from './form/UserAdmin'
import UserPOS from './form/UserPOS'
import UserReservations from './form/UserReservations'
import UserScanner from './form/UserScanner'

import useDependencies from '_shared/hooks/useDependencies'

import getFilteredProfiles from './form/getFilteredProfiles'

import useNav from '_shared/hooks/useNav'

import {
  handlers,
} from './form/Context'

const UsersList = ({
  permissions: permissionsList,
}) => {
  const {
    open,
  } = useNav('subview')

  const { data: allProfiles, isFetching } = useDependencies('profiles')

  const filtered = getFilteredProfiles(allProfiles)

  const create = () => open(`new_${handlers.store}`)

  const permissions = permissionsList['user']

  const options = [
    {
      label: 'All users',
      view: () => (
        <UserAll
          data={filtered.ALL}
          edit={open}
          create={create}
          permissions={permissions}
          loading={isFetching}
        />
      ),
      slug: 'all',
    },
    {
      label: 'Admin users',
      view: () => (
        <UserAdmin
          data={filtered.ADMIN}
          edit={open}
          create={create}
          permissions={permissions}
        />
      ),
      slug: 'admin',
    },
    {
      label: 'POS users',
      view: () => (
        <UserPOS
          data={filtered.POS}
          edit={open}
          create={create}
          permissions={permissions}
        />
      ),
      slug: 'pos',
    },
    {
      label: 'Reservations users',
      view: () => (
        <UserReservations
          data={filtered.RESERVATIONS}
          edit={open}
          create={create}
          permissions={permissions}
        />
      ),
      slug: 'reservations',
    },
    {
      label: 'Scanning app users',
      view: () => (
        <UserScanner
          data={filtered.SCANNER}
          edit={open}
          create={create}
          permissions={permissions}
        />
      ),
      slug: 'scanning',
    },
  ]

  const {
    selected,
    changeView,
  } = useNavigationView(options)

  const current = options.find(option => option.slug === selected)

  const Inner = current.view

  return (
    <Fragment>
      <Consumer />
      <View>
        <Heading
          level={'section'}
          title={'User Administration'}
        />
        <ShadowWrapper>
          <TabbedGroup
            options={options}
            action={changeView}
            selected={selected}
          />
        </ShadowWrapper>
        <Inner />
      </View>
    </Fragment>
  )
}

const mapStateToProps = (state) => {
  const {
    user,
  } = state.user

  return {
    permissions: user.permissions,
  }
}

export default connect(mapStateToProps)(UsersList)

export {
  NavIcon,
}
