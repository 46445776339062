import React, { Fragment, useState } from 'react'

import Area from '_shared/components/layout/Area'
import FormView from '_shared/components/layout/FormView'
import Container from '_shared/components/layout/Container'
import Row from '_shared/components/layout/Row'
import InlineTextBox from '_shared/components/layout/InlineTextBox'
import { Raw as SideNavigation } from '_shared/components/navigation/SideNavigation'
import SectionWrapper from '_shared/components/layout/SectionWrapper'

import Heading from '_shared/components/layout/Heading'
import TextBox from '_shared/components/layout/TextBox'

import ListTable from '_shared/components/navigation/ListTable'

import { Raw as InputCurrency } from '_shared/components/input/InputCurrency'
import { Raw as InputPercent } from '_shared/components/input/InputPercent'
import InputSelect from '_shared/components/input/InputSelect'

import useLocale from '_shared/hooks/useLocale'
import useDependencies from '_shared/hooks/useDependencies'

import InputCheckBox from '_shared/components/input/InputCheckBox'

import TransferIcon from '_shared/icons/Transfer'
import OutboundIcon from '_shared/icons/Outbound'
import {
  parseValidity,
} from '_shared/libs/validityChecks'

import { getDeepLocale } from '_shared/libs/nestedDataHelpers'
import { update } from '_shared/libs/mapToSchema'

import { findExistingEffect } from 'templates/PriceAdjustMatrix'

import Config from 'libs/Config'
import orderBy from 'lodash/orderBy'
import keyBy from 'lodash/keyBy'
import FeeTable from 'templates/FeeTable'


const generateRouteLocations = (routes) => {
  return routes.map(route => {
    const {
      arrival_location,
      departure_location,
      legs,
    } = route

    const departure_entity = departure_location ? departure_location.entity_id : legs[0].departure_location.entity_id
    const arrival_entity = arrival_location ? arrival_location.entity_id : legs[legs.length - 1].arrival_location.entity_id

    return {
      departure_entity,
      arrival_entity,
      isMultiLeg: legs.length > 1,
    }
  })
}

const FormPricing = ({
  current,
  valid,
  updateSingle,
}) => {
  const {
    default_locale,
  } = useLocale()

  const [selected, setSelected] = useState('default')

  const { data: locations } = useDependencies('locations')
  const { data: markets } = useDependencies('markets')
  const { data: ticket_groups } = useDependencies('ticket_groups')
  const { data: ticket_types } = useDependencies('ticket_types', data => data
    .reduce((acc, { entity_id, locales, ticket_type_group }) => {
      const title = getDeepLocale(locales, 'title', default_locale)

      const ticket_group = ticket_groups.find(group => group.entity_id === ticket_type_group.entity_id)

      const group = ticket_group ? `${getDeepLocale(ticket_group.locales, 'title', default_locale)}: ` : ''

      acc[entity_id] = `${group}${title}`

      return acc
    }, {}))
  const { data: terms } = useDependencies('terms', data => data
    .map(({ entity_id, locales }) => {
      const title = locales.find(({ locale }) => locale.toLowerCase().includes(default_locale))
      return {
        label: title ? title.title : 'undefined',
        value: entity_id,
      }
    }),
  )

  const lookup = locations?.reduce((list, location) => {
    list[location.entity_id] = {
      ...location,
      title: getDeepLocale(location.locales, 'title', default_locale),
    }
    return list
  }, {})

  const { routes } = current

  const route_options = orderBy(generateRouteLocations(routes)
    .map(({
      departure_entity,
      arrival_entity,
      isMultiLeg,
    }) => {
      return {
        departure_entity,
        arrival_entity,
        isMultiLeg,
        meta: {
          id: `${departure_entity}:${arrival_entity}`,
          start: lookup[departure_entity]?.external_id,
          end: lookup[arrival_entity]?.external_id,
          startLabel: lookup[departure_entity]?.title,
          endLabel: lookup[arrival_entity]?.title,
        },
      }
    }), ['meta.id'])

  const route_hash = keyBy(route_options, 'meta.id')

  const selectedRoute = route_hash[selected] ? route_hash[selected].meta : selected

  const route_pairs = route_options.map(({
    departure_entity,
    arrival_entity,
  }) => ({
    departure_entity,
    arrival_entity,
  }))

  console.log('PRICING CURRENT', current)

  return (
    <FormView>
      <Area
        areas={[
          'section_type',
          'pricing',
          'booking_fees',
          'deposit_amounts',
          'minimum_spend',
          'section_vat',
          'vat/3 .',
          'section_terms',
          'terms . . .',
        ]}
      >
        <SectionWrapper area={'section_type'}>
          <Heading level={1} title={'Ticket Type'} />
        </SectionWrapper>
        <Area
          areas={current.type === 'route' ? ['nav grid/3'] : ['grid']}
          area={'pricing'}
          columns={4}
        >
          {current.type === 'route' && (
            <Container area={'nav'}>
              <Heading
                area={'info'}
                level={2}
                title={'Routes to Price'}
                margin={'0 0 1rem 0'}
              />
              <SideNavigation
                area={'side'}
                options={[
                  {
                    title: 'Product default info',
                    slug: 'default',
                  },
                  {
                    title: '',
                    slug: 'spacer',
                    spacer: true,
                  },
                  ...route_options.map(
                    (route) => {
                      const {
                        isMultiLeg,
                        meta,
                      } = route

                      const Icon = isMultiLeg ? TransferIcon : OutboundIcon

                      return {
                        title: (
                          <Row noFlex>
                            <InlineTextBox rawStyle={{ fontSize: 'inherit', color: 'inherit' }}>
                              {meta.startLabel}
                            </InlineTextBox>
                            <Icon fill={Config.theme.text} size={20} />
                            <InlineTextBox rawStyle={{ fontSize: 'inherit', color: 'inherit' }}>
                              {meta.endLabel}
                            </InlineTextBox>
                          </Row>
                        ),
                        slug: meta.id,
                      }
                    },
                  ),
                ]}
                selected={selected}
                action={setSelected}
                noBorder
              />
            </Container>
          )}
          <Container area={'grid'}>
            <Heading level={2} title={selectedRoute === 'default' ? 'Product Default Price' : `Route: ${selectedRoute.startLabel} -> ${selectedRoute.endLabel} `} />
            <TextBox strong>{'Add pricing for tickets'}</TextBox>
            <PricingSection
              key={JSON.stringify({ route_pairs, selectedRoute })}
              routes={route_pairs}
              selectedRoute={selectedRoute}
              ticket_types={ticket_types}
              current={current}
              updateSingle={updateSingle}
            />
          </Container>
        </Area>
        {(markets.length > 0 && Config.app['show_booking_fees']) && (
          <BookingFeesForm
            area={'booking_fees'}
            current={current}
            markets={markets}
            change={updateSingle}
          />
        )}
        {(markets.length > 0 && Config.app['show_deposit']) && (
          <DepositAmountsForm
            area={'deposit_amounts'}
            current={current}
            markets={markets}
            change={updateSingle}
          />
        )}
        {(markets.length > 0 && Config.app['show_minimum_spend']) && (
          <MinimumSpendForm
            area={'minimum_spend'}
            current={current}
            markets={markets}
            change={updateSingle}
          />
        )}
        <SectionWrapper area={'section_vat'}>
          <Heading level={1} title={'VAT'} />
        </SectionWrapper>
        <VatGrid
          area={'vat'}
          markets={current.sales_limitations.markets.map(({ entity_id }) => markets.find((market) => market.entity_id === entity_id))}
          data={current.vat_rate}
          change={updateSingle}
        />
        <SectionWrapper area={'section_terms'}>
          <Heading level={1} title={'Cancelation & Amendment Fees'} />
        </SectionWrapper>
        <InputSelect
          area={'terms'}
          title={'Template'}
          field={'terms.entity_id'}
          value={current.terms.entity_id}
          placeholder={'Choose terms'}
          options={terms}
          change={updateSingle}
          status={parseValidity(valid.terms)}
          required
          requiredText={'Product requires cancelation definition'}
        />
      </Area>
    </FormView>
  )
}

const generateEffects = ({
  markets,
  tickets,
  routes = [],
  data = [],
}) => {
  const init_value = null
  const init_type = 'set_fixed'

  const routes_map = [...routes]

  routes_map
    .push({
      departure_entity: null,
      arrival_entity: null,
    })

  return tickets
    .reduce((effects, ticket) => {
      markets
        .forEach(market => {
          routes_map
            .forEach(({ departure_entity, arrival_entity }) => {
              // Search for an existing record here and use its values
              const exists = findExistingEffect({
                channel: null,
                ticket,
                market,
                location_id: departure_entity,
                end_location_id: arrival_entity,
                data,
              })

              if (exists) {
                effects.push({
                  ...exists,
                  type: exists.type,
                  value: exists.value,
                  market_id: market,
                  channel: null,
                  ticket_type_id: ticket,
                  location_id: departure_entity,
                  end_location_id: arrival_entity,
                })
              } else {
                effects.push({
                  type: init_type,
                  value: departure_entity === null && arrival_entity === null ? 0 : init_value,
                  market_id: market,
                  channel: null,
                  ticket_type_id: ticket,
                  location_id: departure_entity,
                  end_location_id: arrival_entity,
                })
              }
            })
        })

      return effects
    }, [])
    .map((effect, index) => ({ ...effect, index }))
}

const flattener = ({
  effects,
  depart = null,
  arrive = null,
}) => {
  return effects
    .filter(({ location_id, end_location_id }) => (
      location_id === depart &&
      end_location_id === arrive
    ))
    .reduce((acc, effect) => {
      if (acc[effect.ticket_type_id]) {
        acc[effect.ticket_type_id].push({
          ...effect,
        })

      } else {
        acc[effect.ticket_type_id] = [
          {
            ...effect,
          },
        ]
      }

      return acc
    }, {})
}

const PricingSection = ({
  selectedRoute,
  ticket_types,
  routes = [],
  current,
  updateSingle,
}) => {
  let location = null
  let end_location = null

  if (selectedRoute && selectedRoute.id) {
    const [
      location_id,
      end_location_id,
    ] = selectedRoute.id.split(':')

    location = location_id
    end_location = end_location_id
  }

  const effects = generateEffects({
    markets: current.sales_limitations.markets.map(({ entity_id }) => entity_id),
    tickets: current.allowed_ticket_types.map(item => item.ticket_type.entity_id),
    routes,
    data: current.pricing,
  })

  const withValues = current.pricing
    .filter(({ location_id, end_location_id, value }) => (
      location_id === location &&
      end_location_id === end_location &&
      value !== null
    ))

  const hasPricing = (
    location !== null &&
    end_location !== null &&
    withValues.length > 0
  )

  const [useDefault, setUseDefault] = useState(!hasPricing)

  const handleSetToDefault = (field, value) => {
    if (value) {
      const state = [...effects]
        .map(effect => {
          const {
            location_id,
            end_location_id,
          } = effect

          if (location_id === location && end_location_id === end_location) {
            effect.value = null
          }

          return effect
        })

      updateSingle('pricing', state)
    } else {
      const state = [...effects]
        .map(effect => {
          const {
            location_id,
            end_location_id,
          } = effect

          if (location_id === location && end_location_id === end_location) {
            effect.value = 0
          }

          return effect
        })

      updateSingle('pricing', state)
    }

    setUseDefault(value)
  }

  return (
    <Fragment>
      {selectedRoute !== 'default' && (
        <InputCheckBox
          field={'use_default'}
          value={useDefault}
          title={'Use the product default for the legs in this route'}
          change={handleSetToDefault}
          margin={'0 0 1rem 0'}
        />
      )}
      <PricingGrid
        effects={effects}
        activeRoute={selectedRoute}
        ticket_types={ticket_types}
        change={value => updateSingle('pricing', value)}
      />
    </Fragment>
  )
}

const columns = [
  {
    key: 'type',
    label: 'Ticket Type',
  },
  {
    key: 'cost',
    label: 'Cost price',
  },
  {
    key: 'price',
    label: 'Price',
  },
]

const fieldStyle = {
  flex: '0 0 auto',
  width: '6rem',
  minHeight: '1.5rem',
}

const PricingGrid = ({
  effects,
  activeRoute,
  ticket_types = {},
  change,
}) => {
  const { data: markets } = useDependencies('markets')

  let location = null
  let end_location = null

  if (activeRoute && activeRoute.id) {
    const [
      location_id,
      end_location_id,
    ] = activeRoute.id.split(':')

    location = location_id
    end_location = end_location_id
  }

  const flattened = flattener({
    effects,
    depart: location,
    arrive: end_location,
  })

  const fetchCurrencyFromMarket = (market_id) => {
    return markets.find((market) => market.entity_id === market_id)?.currencies[0].id
  }

  const updateRecord = (state) => {
    change(state)
  }

  const handleChange = (field, value) => {
    const [
      index,
      field_type,
    ] = field.split('|')

    const state = [...effects]

    update(state, `[${index}]${field_type}`, value)

    updateRecord(state)
  }

  const rows = Object.keys(flattened)
    .reduce((acc, ticket_type_id) => {
      const current = flattened[ticket_type_id]

      const cells = orderBy(current
        .map((cell) => ({
          ...cell,
          market: markets.find((market) => market.entity_id === cell.market_id),
        })), 'market.title', 'asc')

      const emptyStyle = {
        opacity: 0.75,
        background: 'RGB(var(--theme_background_ui))',
        borderWidth: 0,
      }

      acc.push({
        type: ticket_types[ticket_type_id],
        cost: (
          <InputCurrency
            field={'pricing.cost'}
            value={'0.00'}
            {...fieldStyle}
            width={'6rem'}
            disabled
          />
        ),
        price: (
          <Row>
            {cells.map((item, index) => {
              const market = markets.find(market => market.entity_id === item.market_id)

              const baseField = `${item.index}`

              return (
                <Row key={index} rawStyle={{ flexGrow: 0 }} spread={false}>
                  <InlineTextBox rawStyle={{ marginRight: '0.25rem' }}>
                    {market?.external_id.toUpperCase() || 0}
                  </InlineTextBox>
                  <InputCurrency
                    key={baseField}
                    value={item.value}
                    field={`${baseField}|value`}
                    type={'pricing'}
                    controlled={true}
                    {...fieldStyle}
                    width={'6rem'}
                    rawStyle={item.value === null ? emptyStyle : {}}
                    change={handleChange}
                    currency={fetchCurrencyFromMarket(item.market_id)}
                    status={item.value !== null ? 'VALID' : null}
                  />
                </Row>
              )
            })}
          </Row>
        ),
      })
      return acc
    }, [])

  return (
    <ListTable
      area={'pricing'}
      columns={columns}
      data={rows}
      shadeAlternateRows={false}
    />
  )
}


const VatGrid = ({
  markets = [],
  data,
  change,
}) => {
  const marketIds = markets.map(e => e?.entity_id)

  const columns = [
    {
      key: 'title',
      label: '',
    },
    ...data.filter(
      (el) => marketIds.includes(el.market.entity_id),
    ).map((item, row) => {
      const label = markets.find(market => market.entity_id === item.market?.entity_id)
      return {
        key: row,
        label: label ? label.title : 'undefined',
      }
    }),
  ]

  const mutatedData = [
    {
      title: 'Sales Tax/VAT',
      ...(
        data
          .reduce((acc, cur, row) => {
            acc[row] = (
              <InputPercent
                field={`vat_rate[${row}].amount`}
                value={cur.amount}
                width={'6rem'}
                {...fieldStyle}
                change={change}
              />
            )

            return acc
          }, {})
      ),
    },
  ]
  return (
    <ListTable
      area={'pricing'}
      columns={columns}
      data={mutatedData}
    />
  )
}

const getActiveMarkets = (selected, markets) => {
  return orderBy(selected.map(({ entity_id }) => {
    return markets.find(market => market.entity_id === entity_id)
  }), 'title')
}

const feeOptions = [
  { value: 'inactive', label: 'No booking fees' },
  { value: 'per_booking', label: 'Per booking' },
  { value: 'per_ticket', label: 'Per ticket' },
]

const BookingFeesForm = ({ current, change, markets }) => {
  const activeMarkets = getActiveMarkets(current.sales_limitations.markets, markets)

  return (
    <FeeTable
      field={'booking_fee'}
      refundField={'booking_fee_refundable'}
      title={'Booking Fees'}
      enabled={current.booking_fee_enabled}
      selected={current.booking_fee_type}
      options={feeOptions}
      markets={activeMarkets}
      value={current.booking_fee_values}
      refundValue={current.booking_fee_refundable}
      change={change}
      showRefund
    />
  )
}

const depositFeeOptions = [
  { value: 'inactive', label: 'No deposit' },
  { value: 'per_booking', label: 'Per booking' },
  { value: 'per_ticket', label: 'Per ticket' },
]

const DepositAmountsForm = ({ current, change, markets }) => {
  const activeMarkets = getActiveMarkets(current.sales_limitations.markets, markets)

  return (
    <FeeTable
      field={'deposit'}
      title={'Deposit'}
      enabled={current.deposit_enabled}
      selected={current.deposit_type}
      options={depositFeeOptions}
      markets={activeMarkets}
      value={current.deposit_values}
      change={change}
    />
  )
}

const minSpendOptions = [
  { value: 'inactive', label: 'No minimum spend' },
  { value: 'per_booking', label: 'Per booking' },
]

const MinimumSpendForm = ({ current, change, markets }) => {
  const activeMarkets = getActiveMarkets(current.sales_limitations.markets, markets)

  return (
    <FeeTable
      field={'minimum_spend'}
      title={'Minimum Spend'}
      enabled={current.minimum_spend_enabled}
      selected={current.minimum_spend_type}
      options={minSpendOptions}
      markets={activeMarkets}
      value={current.minimum_spend_values}
      change={change}
    />
  )
}

export default FormPricing
