import React from 'react'

import Container from '_shared/components/layout/Container'
import InlineTextBox from '_shared/components/layout/InlineTextBox'

import Heading from '_shared/components/layout/Heading'
import TextBox from '_shared/components/layout/TextBox'

import Area from '_shared/components/layout/Area'
import InputSelect from '_shared/components/input/InputSelect'

import rolesList from 'config/roles'

import upperCaseFirst from 'libs/upperCaseFirst'

import Config from 'libs/Config'

const adminOptions = [
  {
    label: 'No Access',
    value: 'blocked',
  },
  {
    label: 'Owner',
    value: 'owner',
  },
  {
    label: 'Administrator',
    value: 'admin',
  },
  {
    label: 'Editor',
    value: 'editor',
  },
  {
    label: 'Viewer',
    value: 'viewer',
  },
  {
    label: 'Billing',
    value: 'billing',
  },
]

const reservationsOptions = [
  {
    label: 'No Access',
    value: 'blocked',
  },
  {
    label: 'Owner',
    value: 'owner',
  },
  {
    label: 'Administrator',
    value: 'admin',
  },
  {
    label: 'Editor',
    value: 'editor',
  },
  {
    label: 'Viewer',
    value: 'viewer',
  },
  {
    label: 'Billing',
    value: 'billing',
  },
]

const posOptions = [
  {
    label: 'No Access',
    value: 'blocked',
  },
  {
    label: 'Owner',
    value: 'owner',
  },
  {
    label: 'Administrator',
    value: 'admin',
  },
  {
    label: 'Editor',
    value: 'editor',
  },
  {
    label: 'Viewer',
    value: 'viewer',
  },
  {
    label: 'Billing',
    value: 'billing',
  },
]

const scannerOptions = [
  {
    label: 'No Access',
    value: 'blocked',
  },
  {
    label: 'Full Access',
    value: 'editor',
  },
]

const roleMap = {
  admin: 'blocked',
  reservations: 'blocked',
  pos: 'blocked',
  scanner: 'blocked',
}

const getCurrentPermissions = (config, app, role) => {
  const appSet = config[app]

  return Object.keys(appSet)
    .reduce((actions, rule) => {
      const [
        action,
        modifier,
      ] = rule.split(':')

      const index = actions.findIndex(item => item.resource === action)

      if (index === -1) {
        actions.push({
          resource: action,
          values: {
            [modifier]: appSet[rule][role],
          },
        })
      } else {
        actions[index].values[modifier] = appSet[rule][role]
      }

      return actions
    }, [])
}

const Permissions = (props) => {
  const {
    roles,
    update,
    status,
  } = props

  const data = {
    ...roleMap,
  }

  roles
    .split(',')
    .forEach(item => {
      const [
        portal,
        role,
      ] = item.split(':')

      if (data[portal]) data[portal] = role
    })

  const change = (field, value) => {
    const temp = {
      ...data,
      [field]: value,
    }

    const output = []

    for (const portal in temp) {
      if (temp[portal] !== 'blocked') output.push(`${portal}:${temp[portal]}`)
    }

    update('roles', output.join(','))
  }

  return (
    <Area
      areas={[
        'admin reservations pos scanner',
        'error',
      ]}
    >
      <Container area={'admin'}>
        <Heading level={3} title={'Ticknovate Admin'} />
        <Selector
          field={'admin'}
          value={data.admin}
          change={change}
          options={adminOptions}
        />
        <PermissionList
          app={'admin'}
          role={data.admin}
        />
      </Container>
      <Container area={'reservations'}>
        <Heading level={3} title={'Reservations'} />
        <Selector
          field={'reservations'}
          value={data.reservations}
          change={change}
          options={reservationsOptions}
        />
        <PermissionList
          app={'reservations'}
          role={data.reservations}
        />
      </Container>
      <Container area={'pos'}>
        <Heading level={3} title={'POS'} />
        <Selector
          field={'pos'}
          value={data.pos}
          change={change}
          options={posOptions}
        />
        <PermissionList
          app={'pos'}
          role={data.pos}
        />
      </Container>
      <Container area={'scanner'}>
        <Heading level={3} title={'Scanner'} />
        <Selector
          field={'scanner'}
          value={data.scanner}
          change={change}
          options={scannerOptions}
        />
      </Container>
      {status === 'error' && (
        <TextBox color={'text_error'} area={'error'}>
          {'* User must have access to "admin", "reservations" or "scanner" for verification purposes'}
        </TextBox>
      )}
    </Area>
  )
}

const PermissionList = (props) => {
  const {
    app,
    role,
  } = props

  const mod = {
    fontSize: '0.75rem',
    marginRight: '0.25rem',
  }

  const disabled = {
    textDecoration: 'line-through',
  }

  const layout = {
    marginTop: '1rem',
    borderTop: `1px solid ${Config.theme.border_thick}`,
  }

  const row = {
    borderBottom: `1px solid ${Config.theme.border_thin}`,
    padding: '0.5rem 0',
  }

  return (
    <Container rawStyle={layout}>
      {role !== 'blocked' && getCurrentPermissions(rolesList, app, role)
        .map(actions => {
          const {
            resource,
            values,
          } = actions

          return (
            <Container rawStyle={row} key={resource}>
              <InlineTextBox strong>{resource.split('_').map(upperCaseFirst).join(' ')}</InlineTextBox>
              <Container>
                {Object.keys(values)
                  .map((key, index, array) => {
                    const style = {
                      ...mod,
                      ...(!values[key] && disabled),
                    }

                    return (
                      <InlineTextBox
                        key={key}
                        rawStyle={style}
                      >
                        {`${key.split('_').map(upperCaseFirst).join(' ')}${index !== array.length - 1 ? ',' : ''}`}
                      </InlineTextBox>
                    )
                  })}
              </Container>
            </Container>
          )
        })
      }
    </Container>
  )
}

const Selector = (props) => {
  const {
    field,
    value,
    change,
    options,
  } = props

  return (
    <InputSelect
      title={'Select user rights'}
      field={field}
      options={options}
      placeholder={'Select user rights'}
      change={change}
      value={value}
    />
  )
}

export default Permissions
