import React, { useState } from 'react'

import Area from '_shared/components/layout/Area'
import FormView from '_shared/components/layout/FormView'

import Heading from '_shared/components/layout/Heading'
import SectionWrapper from '_shared/components/layout/SectionWrapper'
import InputInteger from '_shared/components/input/InputInteger'
import InputISOInterval from '_shared/components/input/InputISOInterval'
import InputDate from '_shared/components/input/InputDate'
import InputTime from '_shared/components/input/InputTime'

import TextBox from '_shared/components/layout/TextBox'
import InputRadio from '_shared/components/input/InputRadio'

import DayOfWeek from 'templates/DayOfWeek'

const getScanType = (value) => {
  return value === null ? 'unlimited' : value === 1 ? 'single' : 'multi'
}

const unitMap = {
  'time': {
    type: 'time',
    blocks: [
      {
        conditions: {
          start_time: null,
          end_time: null,
        },
      },
    ],
  },
  'date': {
    type: 'date',
    blocks: null,
  },
  'open': {
    type: 'open',
    blocks: [
      {
        conditions: {

        },
      },
    ],
    first_use_within: {
      period: 'PT24H',
      type: 'period',
    },
  },
}

const FormValidity = ({
  current,
  update,
  updateSingle,
}) => {
  const [
    scan_type, // single | multi | unlimited
    set_scan_type,
  ] = useState(getScanType(current.multi_usage_rules.max_uses))

  const handleTypeChange = (field, value) => {
    if (value === 'single') {
      updateSingle('multi_usage_rules.max_uses', 1)
    }

    if (value === 'multi') {
      updateSingle('multi_usage_rules.max_uses', 2)
    }

    if (value === 'unlimited') {
      updateSingle('multi_usage_rules.max_uses', null)
    }

    set_scan_type(value)
  }

  const handleBookingTypeChange = (field, value) => { // time | date | open
    if (value === 'open') {
      update([
        {
          field: 'service_instance_conditions.start_date',
          value: null,
        },
        {
          field: 'service_instance_conditions.end_date',
          value: null,
        },
        {
          field: 'service_instance_conditions.day_of_week',
          value: [],
        },
        {
          field: 'booking_unit',
          value: { ...unitMap['open'] },
        },
        {
          field: 'multi_usage_rules.active_period',
          value: {
            type: 'period',
            period: 'PT24H',
          },
        },
      ])
    }

    if (value === 'date') {
      update([
        {
          field: 'service_instance_conditions.start_date',
          value: null,
        },
        {
          field: 'service_instance_conditions.end_date',
          value: null,
        },
        {
          field: 'service_instance_conditions.day_of_week',
          value: [],
        },
        {
          field: 'booking_unit',
          value: { ...unitMap['date'] },
        },
      ])
    }

    if (value === 'time') {
      update([
        {
          field: 'service_instance_conditions.start_date',
          value: null,
        },
        {
          field: 'service_instance_conditions.end_date',
          value: null,
        },
        {
          field: 'service_instance_conditions.day_of_week',
          value: [],
        },
        {
          field: 'booking_unit',
          value: { ...unitMap['time'] },
        },
      ])
    }
  }

  return (
    <FormView>
      <Area
        areas={[
          'section_usage',
          'usage',
          'section_validity',
          'validity',
        ]}
        rowgap={1.5}
      >
        <SectionWrapper area={'section_usage'}>
          <Heading level={1} title={'Ticket can be used/scanned'} />
        </SectionWrapper>
        <Area
          area={'usage'}
          areas={[
            'info',
            'selector/2 . .',
          ]}
          rowgap={1}
        >
          <TextBox strong area={'info'}>{'How many times can the ticket be used for these services?'}</TextBox>
          <Area
            area={'selector'}
            areas={[
              'radio',
              'scan_value . . .',
            ]}
            rowgap={1}
            colgap={1}
          >
            <InputRadio
              area={'radio'}
              type={'horizontal'}
              field={'scan_type'}
              value={scan_type}
              options={[
                {
                  value: 'single',
                  label: 'Single use (Scan once)',
                },
                {
                  value: 'multi',
                  label: 'Multi use (E.g. 12 scans)',
                },
                {
                  value: 'unlimited',
                  label: 'Unlimited uses',
                },
              ]}
              change={handleTypeChange}
            />
            {scan_type === 'multi' && (
              <InputInteger
                area={'scan_value'}
                title={'Scan amount'}
                field={'multi_usage_rules.max_uses'}
                value={current.multi_usage_rules.max_uses === null ? 0 : current.multi_usage_rules.max_uses}
                change={updateSingle}
                min={1}
                disabled={scan_type !== 'multi'}
              />
            )}
          </Area>
        </Area>
        <SectionWrapper area={'section_validity'}>
          <Heading level={1} title={'Ticket validity time'} />
        </SectionWrapper>
        <Area
          area={'validity'}
          areas={[
            'info',
            'type/2 times/2',
          ]}
          rowgap={1.5}
        >
          <TextBox strong area={'info'}>{'Define the validity period for the booked ticket.'}</TextBox>
          <Area
            area={'type'}
            columns={1}
            baseColUnit={1}
            rowgap={1}
          >
            <Heading level={2} title={'Booking Time Range'} />
            <InputRadio
              field={'current.booking_unit.type'}
              value={current.booking_unit.type}
              options={[
                {
                  value: 'time',
                  label: 'Book service day & time (Service schedule)',
                },
                {
                  value: 'date',
                  label: 'Book day access',
                },
                {
                  value: 'open',
                  label: 'Un-dated ticket (use any time)',
                },
              ]}
              change={handleBookingTypeChange}
            />
          </Area>
          {(unitMap[current.booking_unit.type] && current.booking_unit.type !== 'open') && (
            <DateRangeForm
              area={'times'}
              current={current}
              update={update}
              updateSingle={updateSingle}
              showTimes={current.booking_unit.type === 'time'}
            />
          )}
          {(unitMap[current.booking_unit.type] && current.booking_unit.type === 'open') && (
            <DurationForm
              area={'times'}
              current={current}
              update={update}
              updateSingle={updateSingle}
            />
          )}
        </Area>
      </Area>
    </FormView>
  )
}

const DateRangeForm = ({
  area,
  current,
  update,
  updateSingle,
  showTimes = false,
}) => {
  const {
    start_date,
    end_date,
    day_of_week,
    start_time,
    end_time,
  } = current.service_instance_conditions

  const [
    limitDates,
    setLimitDates,
  ] = useState((start_date !== null && end_date !== null))

  const handleLimitChange = (field, value) => {
    if (value) {
      update([
        {
          field: 'service_instance_conditions.start_date',
          value: new Date().toISOString().split('T')[0],
        },
        {
          field: 'service_instance_conditions.end_date',
          value: new Date().toISOString().split('T')[0],
        },
        {
          field: 'service_instance_conditions.day_of_week',
          value: [],
        },
      ])
    } else {
      update([
        {
          field: 'service_instance_conditions.start_date',
          value: null,
        },
        {
          field: 'service_instance_conditions.end_date',
          value: null,
        },
        {
          field: 'service_instance_conditions.day_of_week',
          value: [],
        },
      ])
    }

    setLimitDates(value)
  }

  return (
    <Area
      area={area}
      columns={1}
      baseColUnit={1}
      rowgap={1}
    >
      <Heading level={2} title={'Booking Time Rule'} />
      <InputRadio
        field={'limitDates'}
        value={limitDates}
        type={'horizontal'}
        options={[
          {
            value: false,
            label: 'Don\'t limit dates',
          },
          {
            value: true,
            label: 'Limit dates',
          },
        ]}
        change={handleLimitChange}
      />
      {limitDates && (
        <Area
          areas={[
            'range',
            'days',
            'times',
          ]}
          rowgap={1.5}
        >
          <Area
            area={'range'}
            areas={[
              'info',
              'start . end .',
            ]}
            rowgap={1}
            colgap={1}
            columns={['10rem', '2rem', '10rem', '1fr']}
          >
            <Heading level={3} title={'Date range:'} area={'info'} />
            <InputDate
              area={'start'}
              title={'Start date'}
              field={'service_instance_conditions.start_date'}
              value={start_date || ''}
              placeholder={'Start'}
              change={updateSingle}
            />
            <InputDate
              area={'end'}
              title={'End date'}
              field={'service_instance_conditions.end_date'}
              value={end_date || ''}
              placeholder={'End'}
              change={updateSingle}
            />
          </Area>
          <Area
            area={'days'}
            areas={[
              'info',
              'field',
            ]}
            rowgap={1}
          >
            <Heading level={3} title={'Days of the week:'} area={'info'} />
            <DayOfWeek
              area={'field'}
              field={'service_instance_conditions.day_of_week'}
              value={day_of_week}
              change={updateSingle}
            />
          </Area>
          {showTimes && (
            <Area
              area={'times'}
              areas={[
                'info',
                'start . end .',
              ]}
              rowgap={1}
              colgap={1}
              columns={['10rem', '2rem', '10rem', '1fr']}
            >
              <Heading level={3} title={'Limit times between'} area={'info'} />
              <InputTime
                area={'start'}
                title={'Start time'}
                field={'service_instance_conditions.start_time'}
                value={start_time || ''}
                placeholder={'Start'}
                change={updateSingle}
              />
              <InputTime
                area={'end'}
                title={'End time'}
                field={'service_instance_conditions.end_time'}
                value={end_time || ''}
                placeholder={'End'}
                change={updateSingle}
              />
            </Area>
          )}
        </Area>
      )}
    </Area>
  )
}

const DurationForm = ({
  area,
  current,
  updateSingle,
}) => {
  if (!current.booking_unit.blocks) return null

  return (
    <Area
      area={area}
      areas={[
        'section',
        'title_first',
        'first . . .',
        'title_expire',
        'expire . . .',
      ]}
      rowgap={1.5}
    >
      <Heading level={2} title={'Booking Time Rule'} area={'section'} />
      <Heading level={3} title={'Ticket first use within'} area={'title_first'} />
      <InputISOInterval
        area={'first'}
        title={'First use period'}
        field={'booking_unit.first_use_within.period'}
        value={current.booking_unit.first_use_within.period}
        change={updateSingle}
      />
      <Heading level={3} title={'Time of validity after first use'} area={'title_expire'} />
      <InputISOInterval
        area={'expire'}
        title={'Validity period'}
        field={'multi_usage_rules.active_period.period'}
        value={current.multi_usage_rules.active_period.period}
        change={updateSingle}
      />
    </Area>
  )
}

export default FormValidity
