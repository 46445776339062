import { createSchema } from 'morphism'

import {
  mapDefaults,
  mapToArraySubSchema,
} from '_shared/libs/mapToSchema'

import {
  getPriceAdjustmentSchema,
} from 'templates/PriceAdjustMatrix'

const sanitizeTime = (time) => {
  if (!time) return null

  const [
    hours,
    minutes,
  ] = time.split(':')

  return `${hours}:${minutes}`
}

const getDateConditionSchema = () => {
  return createSchema(
    {
      start_date: 'start_date',
      end_date: 'end_date',
      start_time: {
        path: 'start_time',
        fn: sanitizeTime,
      },
      end_time: {
        path: 'end_time',
        fn: sanitizeTime,
      },
      before_offset_from_now: 'before_offset_from_now',
      after_offset_from_now: 'after_offset_from_now',
      day_of_week: 'day_of_week',
    },
    mapDefaults({
      'start_date': new Date().toISOString().split('T')[0],
      'end_date': new Date().toISOString().split('T')[0],
      'day_of_week': [0, 1, 2, 3, 4, 5, 6],
    }),
  )
}

const getPricingRuleSchema = () => {
  return createSchema(
    {
      title: 'title',
      conditions: {
        date: {
          path: 'conditions.date',
          fn: mapToArraySubSchema(getDateConditionSchema),
        },
      },
      price_adjustment: {
        path: 'price_adjustment',
        fn: mapToArraySubSchema(getPriceAdjustmentSchema),
      },
    },
    mapDefaults({
      'title': '',
    }),
  )
}

const valid = {
  title: (record, value) => {
    return value !== ''
  },
  conditions: (record, { date }) => {
    return date.length > 0
  },
}

export {
  getPricingRuleSchema,
  valid,
}
