import deepmerge from 'deepmerge'

import base from '../config/base'

const fetch = window.fetch

// These need to be set as they will be called as soon as we even import something
const defaults = {
  remote: {},
  env: {},
  app: {},
  form_extension: {},
  form_integration: null,
  theme: {},
  theme_raw: {},
  colors: {},
  icons: {},
}

const createStyleElement = (id) => {
  const styleEl = document.createElement('style')

  styleEl.setAttribute('ID', id)

  styleEl.appendChild(document.createTextNode(''))

  document.head.appendChild(styleEl)

  return styleEl.sheet
}

const cssVariables = (theme) => {
  const {
    fontMain,
    fontSecondary,
    fontSectionWeight,
    palette,
    styling,
  } = theme

  const fontSheet = createStyleElement('font')

  const styleSheet = createStyleElement('theme')

  const rules = []
  const colors = {}
  const raw = {}

  if (palette && styling) {
    for (let style in styling) {
      rules.push(`--theme_${style}: ${palette[styling[style]]};`)

      const alpha = styling[style].replace(' ', '').split(',').length === 4

      colors[`${style}`] = `${alpha ? 'rgba' : 'rgb'}(${palette[styling[style]]})`
      raw[`${style}`] = palette[styling[style]]
    }

    // Add ui_gradient
    rules.push(`--gradient: linear-gradient(to bottom, rgb(${palette.white}) 0%,rgb(${palette.grey_2}) 100%);`)
  }

  rules.push(`--font_main: '${fontMain}', sans-serif;`)
  rules.push(`--font_secondary: '${fontSecondary}', sans-serif;`)
  rules.push(`--font_section_weight: ${fontSectionWeight};`)

  if (fontMain === fontSecondary) {
    fontSheet.insertRule(`@import url('https://fonts.googleapis.com/css?family=${fontMain.replace(' ', '+')}:300,400,500,700);`, 0)
  } else {
    fontSheet.insertRule(`@import url('https://fonts.googleapis.com/css?family=${fontMain.replace(' ', '+')}:300,400,500,700|${fontSecondary.replace(' ', '+')}:300,400,500,700');`, 0)
  }

  styleSheet.insertRule(`:root { ${rules.join(' ')} }`)

  return {
    colors,
    raw,
  }
}

// Placeholder for when we have the backend support to parse the URL
const parseEndpoint = (hostname = document.location.hostname) => {
  const endpoint = process.env.REACT_APP_LOCAL_CONFIG_URL_OVERWRITE || `https://${hostname}/config`
  const tenant = process.env.REACT_APP_LOCAL_CONFIG_TENANT_OVERRIDE || hostname.split('.')[0].split('-')[0]


  return {
    endpoint,
    // Parse tenant from hostname. Examples:
    // - bluefunnel.admin-intqa.ticknovate.com -> 'bluefunnel'
    // - bluefunnel.admin-dev.ticknovate.com   -> 'bluefunnel'
    // - forsea.admin.ticknovate.com           -> 'forsea'
    tenant,
  }
}

const options = {
  method: 'GET',
  headers: { 'Accept': 'application/json' },
}

const getConfig = (endpoint) => fetch(endpoint, options)
  .then(response => {
    return response.json()
  })

const parseConfig = (defaultConfig, remoteConfig) => {
  const {
    theme,
    ...remoteConfigRest
  } = remoteConfig

  const colors = cssVariables(theme)
  const localConfigOverwrite = process.env.REACT_APP_LOCAL_CONFIG_OVERWRITE_ADMIN
    ? JSON.parse(process.env.REACT_APP_LOCAL_CONFIG_OVERWRITE_ADMIN)
    : {}

  console.log('Local config overwrite', localConfigOverwrite)

  return deepmerge.all([
    defaultConfig,
    remoteConfigRest,
    {
      colors: colors.colors,
      theme: colors.colors,
      theme_raw: colors.raw,
    },
    localConfigOverwrite,
    {
      remote: remoteConfig,
    },
  ])
}

class ConfigHolder {
  constructor () {
    this.endpoint = null
    this.tenant = null
    this._config = defaults
    this.loaded = false

    for (let key in defaults) {
      Object.defineProperty(this, key, {
        get: function () {
          return this._config[key]
        },
        set: function (new_value) {
          this._config[key] = new_value
        },
      })
    }
  }

  init () {
    const {
      endpoint,
      tenant,
    } = parseEndpoint()

    this._config.env = {
      endpoint,
      tenant,
    }

    this.endpoint = endpoint
    this.tenant = tenant

    window.TICKNOVATE_CONFIG = this._config
  }

  loadConfig = async (force = false) => {
    const {
      endpoint,
      tenant,
    } = this._config.env

    console.log('Is config already loaded?', force, this.loaded)

    if (!force && this.loaded) return

    try {
      const remoteConfig = await getConfig(`${endpoint}/${tenant}/admin.json`)

      this._config = parseConfig(this._config, remoteConfig)

      this.loaded = true

      window.TICKNOVATE_CONFIG = this._config

      return this._config
    } catch (error) {
      console.log('No config, setting to default', error)

      try {
        const remoteConfig = await getConfig(`${endpoint}/default/admin.json`)

        this._config = parseConfig(this._config, remoteConfig)

        this.loaded = true

        window.TICKNOVATE_CONFIG = this._config

        return this._config
      } catch (error) {
        console.log('Config server unavailable', error)

        this._config = parseConfig(this._config, base)

        this.loaded = true

        return this._config
      }
    }
  }

  get config () {
    return this._config
  }

  set config (config) {
    this._config = config
  }
}

const Config = new ConfigHolder()

export default Config
