import React, { useState } from 'react'

import FormView from '_shared/components/layout/FormView'
import useLocale from '_shared/hooks/useLocale'
import Area from '_shared/components/layout/Area'
import Heading from '_shared/components/layout/Heading'
import TextBox from '_shared/components/layout/TextBox'
import InputSelect from '_shared/components/input/InputSelect'
import InputTagSelector from '_shared/components/input/InputTagSelector'

import useDependencies from '_shared/hooks/useDependencies'

import {
  getDeepLocale,
} from '_shared/libs/nestedDataHelpers'

import { morphism } from 'morphism'
import { getCapacitySpec } from '../../BuildingBlocks/subviews/Layouts/form/Context'
import InputCheckBox from '_shared/components/input/InputCheckBox'

const FormLayout = ({
  current,
  update,
  updateSingle,
  type,
}) => {
  const [
    layout,
    setLayout,
  ] = useState(null)

  const {
    default_locale,
    locales,
  } = useLocale()

  const { data: ticket_groups } = useDependencies('ticket_groups', data => data.map(({ entity_id, locales }) => {
    return {
      label: getDeepLocale(locales, 'title', default_locale),
      value: entity_id,
    }
  }))

  const { data: venues } = useDependencies(type === 'event' ? 'venues' : 'vehicles')

  const venue_options = venues
    .filter(venue => type === 'event' ? venue.type === 'venue' : venue.type === 'vehicle')
    .map(({ entity_id, locales }) => {
      return {
        label: getDeepLocale(locales, 'title', default_locale),
        value: entity_id,
      }
    })

  let layout_options = []

  if (current.venue.entity_id !== null) {
    const found = venues.find(venue => venue.entity_id === current.venue.entity_id)

    if (found) {
      layout_options = found.capacity_templates
        .map(({ id, capacity_layout }, index) => {
          return {
            label: getDeepLocale(capacity_layout.locales, 'title', default_locale),
            value: index,
            data: morphism(getCapacitySpec(locales), capacity_layout),
          }
        })
    }
  }

  // console.log('LAYOUT OPTIONS', layout_options)

  const handleVenueChange = (field, value) => {
    update([
      {
        field,
        value,
      },
      {
        field: 'compatible_ticket_type_groups',
        value: [],
      },
    ])

    setLayout(null)
  }

  const handleMulti = (field, payload) => updateSingle(field, payload
    .map(entity_id => ({ entity_id })))

  const handleLayout = (field, value) => {
    const found = layout_options.find(item => item.value === value)

    updateSingle('layout', found.data)
    setLayout(value)
  }

  return (
    <FormView>
      <Area
        areas={[
          'section',
          'venue',
          'attendee',
          'layout',
          'single_booking',
        ]}
        rowgap={1.5}
      >
        <Heading area={'section'} level={2} title={'Venue'} />
        <Area
          area={'venue'}
          areas={[
            'info',
            'field . .',
          ]}
          rowgap={0.5}
        >
          <TextBox strong area={'info'}>{'1. Select the venue for this Event:'}</TextBox>
          <InputSelect
            area={'field'}
            title={'Venue'}
            field={'venue.entity_id'}
            value={current.venue.entity_id}
            placeholder={'Choose a Venue'}
            options={venue_options}
            change={handleVenueChange}
            margin={'0 0 0 1rem'}
          />
        </Area>
        <Area
          area={'attendee'}
          areas={[
            'info',
            'field',
          ]}
          rowgap={0.5}
        >
          <TextBox strong area={'info'}>{'2. What can attend this service'}</TextBox>
          <InputTagSelector
            area={'field'}
            field={'compatible_ticket_type_groups'}
            value={current.compatible_ticket_type_groups.map(item => item.entity_id)}
            options={ticket_groups}
            change={handleMulti}
            margin={'0 0 0 1rem'}
          />
        </Area>
        <Area
          area={'layout'}
          areas={[
            'info',
            'field . .',
          ]}
          rowgap={0.5}
        >
          <TextBox strong area={'info'}>{'3. Select the venue\'s Layout'}</TextBox>
          <InputSelect
            area={'field'}
            title={'Venue layout Template'}
            field={'layout.entity_id'}
            value={layout}
            placeholder={'Choose a layout template'}
            options={layout_options}
            change={handleLayout}
            disabled={current.venue.entity_id === null}
            margin={'0 0 0 1rem'}
          />
        </Area>
        <Area
          area={'single_booking'}
          areas={[
            'info',
            'field . .',
          ]}
          rowgap={0.5}
        >
          <TextBox strong area={'info'}>{'4. Single booking only'}</TextBox>
          <InputCheckBox
            area={'field'}
            title={'Single booking only'}
            field={'single_booking_only'}
            value={current.single_booking_only}
            change={updateSingle}
            margin={'0 0 1rem 0'}
          />
        </Area>
      </Area>
    </FormView>
  )
}



export default FormLayout
