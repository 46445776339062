import { update } from '_shared/libs/mapToSchema'

// Find an existing effect and return its value
const findExistingEffect = ({
  channel, // [1,2,3,4,5,6]
  ticket, // entity_id || null
  market, // entity_id || null
  location_id, // entity_id || null
  end_location_id, // entity_id || null
  data, // Array of effects
}) => {
  return data
    .find(effect => (
      effect.channel === channel &&
      effect.ticket_type_id === ticket &&
      effect.market_id === market &&
      effect.location_id === location_id &&
      effect.end_location_id === end_location_id
    ))
}

/*
  Generate an array of all possible permutations of an effect.
  Used by the pricing matrix to populate cells and rows
*/
const generateEffects = ({
  channels, // Array of channels [1,2,3,4,5,6]
  markets, // Array of market options [{ value: entity_id, label: 'International' }]
  tickets, // Array of ticket type entity_ids
  routes = [], // Array of route pairs [{ departure_entity, arrival_entity }]
  data = [], // Existing effects array
}) => {
  const init_value = 0
  const init_type = 'increment_percent'

  const routes_map = [...routes]

  routes_map // Push null into list to create the empty or default location type
    .push({
      departure_entity: null,
      arrival_entity: null,
    })

  const channels_map = [...channels]

  channels_map.push(null) // Push null into list to create empty channel

  const tickets_map = [...tickets]

  tickets_map.push('all') // Push 'all' into list to generate effect for all tickets

  return tickets_map
    .reduce((effects, ticket) => {
      markets
        .forEach(({ value: market }) => {
          channels_map
            .forEach(channel => {
              routes_map
                .forEach(({ departure_entity, arrival_entity }) => {
                  // Search for an existing record here and use its values
                  const exists = findExistingEffect({
                    channel,
                    ticket,
                    market,
                    location_id: departure_entity,
                    end_location_id: arrival_entity,
                    data,
                  })

                  if (exists) {
                    effects.push({
                      ...exists,
                      type: exists.type,
                      value: exists.value,
                      market_id: market,
                      channel: channel,
                      ticket_type_id: ticket,
                      location_id: departure_entity,
                      end_location_id: arrival_entity,
                    })
                  } else { // Generate a fresh effect with default values
                    effects.push({
                      type: init_type,
                      value: init_value,
                      market_id: market,
                      channel: channel,
                      ticket_type_id: ticket,
                      location_id: departure_entity,
                      end_location_id: arrival_entity,
                    })
                  }
                })
            })
        })
      return effects
    }, [])
    .map((effect, index) => ({ ...effect, index })) // Add an index to each row for easy manipulation
}

/*
  Return an object of filtered effects, keyed by ticket_type_id
  Filtered by markey, departure location and arrival location
  If perTicket is false, will return only ticket ID's that are 'all'
*/
const filterByTicket = ({
  effects,
  market,
  depart = null,
  arrive = null,
  perTicket = true,
}) => {
  return effects
    .filter(({ ticket_type_id }) => {
      if (!perTicket) {
        return ticket_type_id === 'all'
      } else {
        return ticket_type_id !== 'all'
      }
    })
    .filter(({ market_id, location_id, end_location_id }) => (
      market_id === market &&
      location_id === depart &&
      end_location_id === arrive
    ))
    .reduce((acc, effect) => {
      if (acc[effect.ticket_type_id]) {
        acc[effect.ticket_type_id].push({
          ...effect,
        })

      } else {
        acc[effect.ticket_type_id] = [
          {
            ...effect,
          },
        ]
      }

      return acc
    }, {})
}

const nonZeroValues = ['increment_fixed', 'increment_percent']

// Filter valid effects for saving/storing
const filterValidEffects = (effects) => {
  const filtered = effects
    .filter(({ value }) => value !== null)
    .filter(({ type, value }) => {
      if (nonZeroValues.includes(type)) {
        return Math.abs(value) !== 0
      } else {
        return true
      }
    })

  return filtered
}

/*
  Filter only effects that are valid for saving
  Remove index value
  Remove any tickets if there is a matching 'all' ticket type
  Add all_ticket_types to any effects that match ticket type 'all
*/
const filterOnSave = (effects) => {
  const validEffects = filterValidEffects(effects)

  const allTickets = validEffects
    .filter(({ ticket_type_id }) => ticket_type_id === 'all')

  let filtered = validEffects

  if (allTickets.length > 0) {
    const allTicketsHash = allTickets
      .map(effect => {
        const {
          market_id,
          location_id,
          end_location_id,
        } = effect

        return `${market_id}|${location_id}|${end_location_id}`
      })

    const cleaned = validEffects
      .filter(effect => {
        const {
          market_id,
          location_id,
          end_location_id,
        } = effect

        const hash = `${market_id}|${location_id}|${end_location_id}`

        return !allTicketsHash.includes(hash)
      })

    filtered = [...cleaned, ...allTickets]
  }

  const output = filtered // Remove indexes for save
    .map(effect => {
      const cleaned = { ...effect }

      if (cleaned.ticket_type_id === 'all') {
        cleaned.ticket_type_id = null
        cleaned.all_ticket_types = true
      }

      delete cleaned.index

      return cleaned
    })

  return output
}

/*
  Return every market in the current set that has valid effects
*/
const hasMarketData = ({
  markets = [],
  activeRoute,
  effects,
}) => {
  let depart = null
  let arrive = null

  if (activeRoute) {
    depart = activeRoute.depart
    arrive = activeRoute.arrive
  }

  const initial = markets
    .reduce((initial, market) => {
      initial[market] = false
      return initial
    }, {})

  const filtered = effects
    .filter(effect => (
      markets.includes(effect.market_id) &&
      effect.location_id === depart &&
      effect.end_location_id === arrive
    ))

  const validEffects = filterValidEffects(filtered)

  return validEffects
    .reduce((valid, effect) => {
      valid[effect.market_id] = true

      return valid
    }, initial)
}

/*
  If mode is switching from 'all' to 'per_ticket'
  - Get value of all
  - Apply value|type to tickets that are id = all

  If mode is switching from 'per_ticket' to 'all'
  - Zero out all tickets that are not id = all
*/
const switchTicketDataMode = ({
  mode, // 'all' || 'per_ticket'
  activeRoute,
  effects,
}) => {
  let depart = null
  let arrive = null

  if (activeRoute) {
    depart = activeRoute.depart
    arrive = activeRoute.arrive
  }

  const reset = [...effects]

  const filtered = reset
    .filter(effect => (
      effect.location_id === depart &&
      effect.end_location_id === arrive
    ))

  if (mode === 'all') {
    const effect_list = filtered
      .filter(({ ticket_type_id }) => ticket_type_id !== 'all')

    effect_list.forEach(({ index }) => {
      update(reset, `[${index}]value`, 0)
      update(reset, `[${index}]type`, 'increment_percent')
    })
  } else {
    const effect_list = filtered
      .filter(({ ticket_type_id }) => ticket_type_id === 'all')

    console.log('FOUND VALUE OF ALL', effect_list)

    effect_list.forEach(({ index }) => {
      update(reset, `[${index}]value`, 0)
      update(reset, `[${index}]type`, 'increment_percent')
    })
  }

  return reset
}

export {
  findExistingEffect,
  generateEffects,
  filterByTicket,
  filterValidEffects,
  filterOnSave,
  hasMarketData,
  switchTicketDataMode,
}
