import React from 'react'

import styles from './select.scss'

import StyleWrapper from '_shared/style/StyleWrapper'

const Select = ({
  options,
  change,
  value,
  placeholder = '',
  ...style
}) => {
  const handleChange = (event) => {
    change(event.target.value)
  }

  return (
    <StyleWrapper
      {...style}
      render={styling => {
        return (
          <select
            style={styling}
            className={styles.select}
            value={value}
            onChange={handleChange}
          >
            <option default disabled>{placeholder}</option>
            {options.map(option => {
              return (
                <option key={option.value} value={option.value}>{option.label}</option>
              )
            })}
          </select>
        )
      }}
    />
  )
}

export default Select
