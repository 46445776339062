import React, { useState, Fragment } from 'react'

import styles from './chunkedtable.scss'

import Nav from './modules/Nav'
import { ListTable, getInitialSortable } from '_shared/components/navigation/ListTable'

import Carousel from './modules/Carousel'

import sortBy from 'lodash/sortBy'

const filterByKey = (data, key, value) => {
  return [...data].filter(record => record[key].toLowerCase().indexOf(value.toLowerCase()) !== -1)
}

const PaginatedTable = ({
  label,
  edit,
  create,
  remove,
  columns,
  data = [],
  loading = false,
  permissions = [],
}) => {
  const [state, setState] = useState({
    searchTerm: '',
    sorted: getInitialSortable(columns),
  })

  const handleSetSearch = (term) => {
    const current = { ...state }

    current.searchTerm = term

    setState(current)
  }

  const handleSetSort = (sorted) => {
    setState({
      sorted,
      searchTerm: '',
    })
  }

  const filter = () => {
    const {
      sorted,
      searchTerm,
    } = state

    const sortedData = sorted !== null ? sortBy(data, [sorted]) : data

    let output = [ ...sortedData ]

    if (searchTerm.length > 2) {
      output = filterByKey(sortedData, sorted, searchTerm)
    }

    return output
  }

  const rows = filter()

  const chunks = []

  while (rows.length) {
    chunks.push(rows.splice(0, 15))
  }

  return (
    <Fragment>
      {create && (
        <Nav
          label={label}
          create={() => create()}
          value={state.searchTerm}
          update={handleSetSearch}
          sorted={state.sorted}
          setSort={handleSetSort}
          columns={columns}
          canCreate={permissions.includes('create')}
        />
      )}
      <Carousel
        slides={1}
        paged
        styling={styles.layout}
      >
        {chunks.map((chunk, index) => {
          return (
            <ListTable
              key={index}
              edit={edit}
              remove={remove}
              columns={columns}
              data={chunk}
              sorted={state.sorted}
              handleSort={handleSetSort}
              loading={loading}
              gridAlign={'stretch'}
              margin={'1rem 0 0 0'}
              canEdit={permissions.includes('update')}
            />
          )
        })}
      </Carousel>
    </Fragment>
  )
}

export default PaginatedTable
