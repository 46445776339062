import React from 'react'

import TextBox from '_shared/components/layout/TextBox'

import Area from '_shared/components/layout/Area'

import Heading from '_shared/components/layout/Heading'
import ActionCTA from '_shared/components/action/ActionCTA'

import ChooserFormat from './choosers/ChooserFormat'
import ChooserChannel from './choosers/ChooserChannel'
import ChooserMarket from './choosers/ChooserMarket'
import ChooserProduct from './choosers/ChooserProduct'
import ChooserService from './choosers/ChooserService'
import ChooserServiceTypes from './choosers/ChooserServiceTypes'
import ChooserSource from './choosers/ChooserSource'
import ChooserStatus from './choosers/ChooserStatus'

import TimeServiceStart from './time/TimeServiceStart'
import TimeServiceEnd from './time/TimeServiceEnd'

import DateServiceStart from './date/DateServiceStart'
import DateServiceEnd from './date/DateServiceEnd'

import DateService from './date/DateService'
import DateBooking from './date/DateBooking'

import TimeService from './time/TimeService'

import MetaFilter from './filter/MetaFilter'
import ProductFilter from './filter/ProductFilter'
import LocationFilter from './filter/LocationFilter'

import YieldDateService from './date/YieldDateService'

import LocationFilterExternalID from './filter/LocationFilterExternalID'
import ChooserExternalOrderType from './choosers/ChooserExternalOrderType'

const fieldMap = {
  fileFormat: {
    field: ChooserFormat,
  },
  channels: {
    field: ChooserChannel,
  },
  markets: {
    field: ChooserMarket,
  },
  products: {
    field: ChooserProduct,
  },
  services: {
    field: ChooserService,
  },
  serviceTypes: {
    field: ChooserServiceTypes,
  },
  source: {
    field: ChooserSource,
  },
  status: {
    field: ChooserStatus,
  },
  metaFilter: {
    field: MetaFilter,
  },
  productFilter: {
    field: ProductFilter,
  },
  locationFilter: {
    field: LocationFilter,
  },
  serviceDate: {
    field: DateService,
  },
  bookingDate: {
    field: DateBooking,
  },
  serviceTime: {
    field: TimeService,
  },
  serviceTimeStart: {
    field: TimeServiceStart,
  },
  serviceTimeEnd: {
    field: TimeServiceEnd,
  },
  serviceDateStart: {
    field: DateServiceStart,
  },
  serviceDateEnd: {
    field: DateServiceEnd,
  },
  yieldServiceDate: {
    field: YieldDateService,
  },
  locationFilterExternalID: {
    field: LocationFilterExternalID,
  },
  externalOrderType: {
    field: ChooserExternalOrderType,
  },
}

const Form = ({
  title,
  description,
  fields,
  layout,
  data,
  change,
  submit,
}) => {
  return (
    <Area
      areas={[
        'title',
        ...layout,
        'submit . .',
      ]}
      columns={3}
      rowgap={2.5}
    >
      <div area={'title'}>
        <Heading
          level={1}
          title={title}
        />
        <TextBox strong>{description}</TextBox>
      </div>
      {fields.map(field => {
        const options = fieldMap[field]
        const Field = options.field

        return (
          <Field
            key={options.key}
            area={field}
            field={options.key}
            data={data}
            change={change}
          />
        )
      })}
      <ActionCTA
        area={'submit'}
        type={'important'}
        label={'Download report'}
        change={submit}
      />
    </Area>
  )
}

export default Form
