import React, { Fragment, useEffect, useState } from 'react'

import Slide, { Controls } from '_shared/components/layout/HistoryFull'

import useActions from '_shared/hooks/useActions'
import useForm from '_shared/hooks/useForm'
import useStatus from '_shared/hooks/useStatus'
import useNav from '_shared/hooks/useNav'
import useDependencies from '_shared/hooks/useDependencies'

import Area from '_shared/components/layout/Area'
import Container from '_shared/components/layout/Container'
import Heading from '_shared/components/layout/Heading'
import TextBox from '_shared/components/layout/TextBox'

import ActionCTA from '_shared/components/action/ActionCTA'

import ModalWrapper from '_shared/components/layout/ModalWrapper'

import { useParams } from 'react-router-dom'

import ExitIcon from '_shared/icons/Exit'
import UpdateIcon from '_shared/icons/Update'
import BackIcon from '_shared/icons/Back'

import Config from 'libs/Config'

const matchKeys = (match, params) => {
  return Object.keys(match)
    .reduce((acc, cur) => {
      return (params[cur] && params[cur] === match[cur])
    }, false)
}

const getUniqueValues = (collection, key) => {
  return collection.map(record => record[key] ? record[key] : null)
}

const FullForm = ({
  data_spec,
  valid_spec,
  handlers,
  mountKey,
  match = null,
  permissionsKey,
  render,
}) => {
  const unique = {}

  useDependencies(handlers.store, data => {
    if (handlers.unique_key) {
      unique[handlers.unique_key] = getUniqueValues(data, handlers.unique_key)
    }
  })

  const [
    modal,
    setModal,
  ] = useState(false)

  const {
    current,
    valid,
    allValid,
    changed,
    reset,
    update,
  } = useForm(data_spec, valid_spec, {}, unique)

  const [
    state,
    dispatch,
  ] = useStatus()

  const {
    permissions,
    load,
    save,
    cancel,
  } = useActions(handlers, permissionsKey, state.status, dispatch, current)

  const {
    close,
  } = useNav(mountKey)

  const params = useParams()

  const wrappedLoad = async (...parameters) => {
    const record = await load(...parameters)

    reset(record)
  }

  useEffect(() => {
    let matched
    if (match) {
      matched = matchKeys(match, params) && params[mountKey[mountKey.length - 1]]
    } else {
      matched = Array.isArray(mountKey) ? params[mountKey[mountKey.length - 1]] : params[mountKey]
    }

    if (matched) {
      if (matched === `new_${handlers.store}`) {
        wrappedLoad()
      } else {
        wrappedLoad(matched)
      }
    } else {
      setModal(false)
    }
  }, [params])

  const wrappedSave = async () => {
    setModal(false)
    await save(current)
    close()
  }

  const wrappedCancel = () => {
    setModal(false)
    cancel()
    close()
  }

  const wrappedConfirm = () => {
    if (!changed) {
      setModal(false)
      cancel()
      close()
    } else {
      setModal(true)
    }
  }

  const wrappedClose = () => {
    setModal(false)
  }

  const updateSingle = (field, value) => update([{ field, value }])

  // TODO: Find a cleaner way of doing this...
  if (state.status === 'waiting') return null

  return (
    <Fragment>
      <ModalWrapper isMounted={modal}>
        <Confirm
          status={state.status}
          save={wrappedSave}
          cancel={wrappedCancel}
          close={wrappedClose}
        />
      </ModalWrapper>
      <Slide
        mountKey={mountKey}
        match={match}
        controls={() => {
          return (
            <Controls
              saveAction={wrappedSave}
              cancelAction={wrappedCancel}
              permissions={permissions}
              disabled={!allValid}
              isMounted={changed}
            />
          )
        }}
      >
        {render({
          cancel: wrappedConfirm,
          current,
          valid,
          reset,
          update,
          updateSingle,
        })}
      </Slide>
    </Fragment>
  )
}

const Confirm = ({
  save,
  cancel,
  close,
}) => {
  return (
    <Area
      areas={[
        'title',
        'exit_icon save_icon cancel_icon',
        'exit_info save_info cancel_info',
        'exit_action save_action cancel_action',
      ]}
      columns={3}
      rowgap={1.5}
      colgap={1}
      padding={'2rem'}
    >
      <Heading level={1} title={'Exiting, you have unsaved changes'} area={'title'} />
      <ExitIcon size={36} fill={Config.theme.button_stop} area={'exit_icon'}/>
      <UpdateIcon size={36} fill={Config.theme.button_success} area={'save_icon'}/>
      <BackIcon size={36} fill={Config.theme.button_cancel} area={'cancel_icon'} />
      <Container area={'exit_info'}>
        <TextBox strong noMargin>{'Exit and discard changes'}</TextBox>
        <TextBox noMargin>{'Leave and discard the changes made since opening'}</TextBox>
      </Container>
      <ActionCTA area={'exit_action'} label={'Exit and Discard Changes'} change={cancel} type={'caution'} />
      <Container area={'save_info'}>
        <TextBox strong noMargin>{'Save your changes'}</TextBox>
        <TextBox noMargin>{'Save your current changes'}</TextBox>
      </Container>
      <ActionCTA area={'save_action'} label={'Save changes'} change={save} type={'important'} />
      <Container area={'cancel_info'}>
        <TextBox strong noMargin>{'Do nothing'}</TextBox>
        <TextBox noMargin>{'Go back to editing'}</TextBox>
      </Container>
      <ActionCTA area={'cancel_action'} label={'Go back'} change={close} type={'unimportant'} />
    </Area>
  )
}

export default FullForm
