import React from 'react'

import Row from '_shared/components/layout/Row'
import Column from '_shared/components/layout/Column'

import defs from 'config/dates'

const DayBar = (props) => {
  const {
    startsOnMonday,
  } = props

  const days = startsOnMonday ? defs.daysAlt : defs.days

  return (
    <Row type={'start'} spread={false} >
      {days.map((day, index) => {
        return (
          <Column
            key={index}
            color={'text'}
            size={0.8}
            width={'calc(100% / 7)'}
            height={'3rem'}
            padding={'0 0.25rem'}
            rawStyle={{ opacity: '0.5' }}
            type={'centered'}
          >
            {day}
          </Column>
        )
      })}
    </Row>
  )
}

export default DayBar
